// Variables
$ember-mdi-css-class: "mdi-icon" !default;
$ember-mdi-spin-delay: 0.65s !default;
$ember-mdi-spin-iteration-count: infinite !default;
$ember-mdi-spin-timing-function: linear !default;

// Main
.#{$ember-mdi-css-class} {
  display: inline-block;
  fill: currentColor;
}

// Animation
.#{$ember-mdi-css-class}-spin {
  animation: #{$ember-mdi-css-class}-spin $ember-mdi-spin-delay $ember-mdi-spin-iteration-count $ember-mdi-spin-timing-function;
}

@keyframes #{$ember-mdi-css-class}-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
