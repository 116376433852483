/* Plain Colors */
.bg-mdb-color.lighten-5 {
	background-color: #d0d6e2 !important;
}

.bg-mdb-color.lighten-4 {
	background-color: #b1bace !important;
}

.bg-mdb-color.lighten-3 {
	background-color: #929fba !important;
}

.bg-mdb-color.lighten-2 {
	background-color: #7283a7 !important;
}

.bg-mdb-color.lighten-1 {
	background-color: #59698d !important;
}

.bg-mdb-color {
	background-color: #45526e !important;
}

.bg-rgba-mdb-color-slight,
.bg-rgba-mdb-color-slight:after {
	background-color: rgba(69, 82, 110, 0.1);
}

.bg-rgba-mdb-color-light,
.bg-rgba-mdb-color-light:after {
	background-color: rgba(69, 82, 110, 0.3);
}

.bg-rgba-mdb-color-strong,
.bg-rgba-mdb-color-strong:after {
	background-color: rgba(69, 82, 110, 0.7);
}

.bg-mdb-color.darken-1 {
	background-color: #3b465e !important;
}

.bg-mdb-color.darken-2 {
	background-color: #2e3951 !important;
}

.bg-mdb-color.darken-3 {
	background-color: #1c2a48 !important;
}

.bg-mdb-color.darken-4 {
	background-color: #1c2331 !important;
}

.bg-red.lighten-5 {
	background-color: #ffebee !important;
}

.bg-red.lighten-4 {
	background-color: #ffcdd2 !important;
}

.bg-red.lighten-3 {
	background-color: #ef9a9a !important;
}

.bg-red.lighten-2 {
	background-color: #e57373 !important;
}

.bg-red.lighten-1 {
	background-color: #ef5350 !important;
}

.bg-red {
	background-color: #f44336 !important;
}

.bg-rgba-red-slight,
.bg-rgba-red-slight:after {
	background-color: rgba(244, 67, 54, 0.1);
}

.bg-rgba-red-light,
.bg-rgba-red-light:after {
	background-color: rgba(244, 67, 54, 0.3);
}

.bg-rgba-red-strong,
.bg-rgba-red-strong:after {
	background-color: rgba(244, 67, 54, 0.7);
}

.bg-red.darken-1 {
	background-color: #e53935 !important;
}

.bg-red.darken-2 {
	background-color: #d32f2f !important;
}

.bg-red.darken-3 {
	background-color: #c62828 !important;
}

.bg-red.darken-4 {
	background-color: #b71c1c !important;
}

.bg-red.accent-1 {
	background-color: #ff8a80 !important;
}

.bg-red.accent-2 {
	background-color: #ff5252 !important;
}

.bg-red.accent-3 {
	background-color: #ff1744 !important;
}

.bg-red.accent-4 {
	background-color: #d50000 !important;
}

.bg-pink.lighten-5 {
	background-color: #fce4ec !important;
}

.bg-pink.lighten-4 {
	background-color: #f8bbd0 !important;
}

.bg-pink.lighten-3 {
	background-color: #f48fb1 !important;
}

.bg-pink.lighten-2 {
	background-color: #f06292 !important;
}

.bg-pink.lighten-1 {
	background-color: #ec407a !important;
}

.bg-pink {
	background-color: #e91e63 !important;
}

.bg-rgba-pink-slight,
.bg-rgba-pink-slight:after {
	background-color: rgba(233, 30, 99, 0.1);
}

.bg-rgba-pink-light,
.bg-rgba-pink-light:after {
	background-color: rgba(233, 30, 99, 0.3);
}

.bg-rgba-pink-strong,
.bg-rgba-pink-strong:after {
	background-color: rgba(233, 30, 99, 0.7);
}

.bg-pink.darken-1 {
	background-color: #d81b60 !important;
}

.bg-pink.darken-2 {
	background-color: #c2185b !important;
}

.bg-pink.darken-3 {
	background-color: #ad1457 !important;
}

.bg-pink.darken-4 {
	background-color: #880e4f !important;
}

.bg-pink.accent-1 {
	background-color: #ff80ab !important;
}

.bg-pink.accent-2 {
	background-color: #ff4081 !important;
}

.bg-pink.accent-3 {
	background-color: #f50057 !important;
}

.bg-pink.accent-4 {
	background-color: #c51162 !important;
}

.bg-purple.lighten-5 {
	background-color: #f3e5f5 !important;
}

.bg-purple.lighten-4 {
	background-color: #e1bee7 !important;
}

.bg-purple.lighten-3 {
	background-color: #ce93d8 !important;
}

.bg-purple.lighten-2 {
	background-color: #ba68c8 !important;
}

.bg-purple.lighten-1 {
	background-color: #ab47bc !important;
}

.bg-purple {
	background-color: #9c27b0 !important;
}

.bg-rgba-purple-slight,
.bg-rgba-purple-slight:after {
	background-color: rgba(156, 39, 176, 0.1);
}

.bg-rgba-purple-light,
.bg-rgba-purple-light:after {
	background-color: rgba(156, 39, 176, 0.3);
}

.bg-rgba-purple-strong,
.bg-rgba-purple-strong:after {
	background-color: rgba(156, 39, 176, 0.7);
}

.bg-purple.darken-1 {
	background-color: #8e24aa !important;
}

.bg-purple.darken-2 {
	background-color: #7b1fa2 !important;
}

.bg-purple.darken-3 {
	background-color: #6a1b9a !important;
}

.bg-purple.darken-4 {
	background-color: #4a148c !important;
}

.bg-purple.accent-1 {
	background-color: #ea80fc !important;
}

.bg-purple.accent-2 {
	background-color: #e040fb !important;
}

.bg-purple.accent-3 {
	background-color: #d500f9 !important;
}

.bg-purple.accent-4 {
	background-color: #aa00ff !important;
}

.bg-deep-purple.lighten-5 {
	background-color: #ede7f6 !important;
}

.bg-deep-purple.lighten-4 {
	background-color: #d1c4e9 !important;
}

.bg-deep-purple.lighten-3 {
	background-color: #b39ddb !important;
}

.bg-deep-purple.lighten-2 {
	background-color: #9575cd !important;
}

.bg-deep-purple.lighten-1 {
	background-color: #7e57c2 !important;
}

.bg-deep-purple {
	background-color: #673ab7 !important;
}

.bg-rgba-deep-purple-slight,
.bg-rgba-deep-purple-slight:after {
	background-color: rgba(103, 58, 183, 0.1);
}

.bg-rgba-deep-purple-light,
.bg-rgba-deep-purple-light:after {
	background-color: rgba(103, 58, 183, 0.3);
}

.bg-rgba-deep-purple-strong,
.bg-rgba-deep-purple-strong:after {
	background-color: rgba(103, 58, 183, 0.7);
}

.bg-deep-purple.darken-1 {
	background-color: #5e35b1 !important;
}

.bg-deep-purple.darken-2 {
	background-color: #512da8 !important;
}

.bg-deep-purple.darken-3 {
	background-color: #4527a0 !important;
}

.bg-deep-purple.darken-4 {
	background-color: #311b92 !important;
}

.bg-deep-purple.accent-1 {
	background-color: #b388ff !important;
}

.bg-deep-purple.accent-2 {
	background-color: #7c4dff !important;
}

.bg-deep-purple.accent-3 {
	background-color: #651fff !important;
}

.bg-deep-purple.accent-4 {
	background-color: #6200ea !important;
}

.bg-indigo.lighten-5 {
	background-color: #e8eaf6 !important;
}

.bg-indigo.lighten-4 {
	background-color: #c5cae9 !important;
}

.bg-indigo.lighten-3 {
	background-color: #9fa8da !important;
}

.bg-indigo.lighten-2 {
	background-color: #7986cb !important;
}

.bg-indigo.lighten-1 {
	background-color: #5c6bc0 !important;
}

.bg-indigo {
	background-color: #3f51b5 !important;
}

.bg-rgba-indigo-slight,
.bg-rgba-indigo-slight:after {
	background-color: rgba(63, 81, 181, 0.1);
}

.bg-rgba-indigo-light,
.bg-rgba-indigo-light:after {
	background-color: rgba(63, 81, 181, 0.3);
}

.bg-rgba-indigo-strong,
.bg-rgba-indigo-strong:after {
	background-color: rgba(63, 81, 181, 0.7);
}

.bg-indigo.darken-1 {
	background-color: #3949ab !important;
}

.bg-indigo.darken-2 {
	background-color: #303f9f !important;
}

.bg-indigo.darken-3 {
	background-color: #283593 !important;
}

.bg-indigo.darken-4 {
	background-color: #1a237e !important;
}

.bg-indigo.accent-1 {
	background-color: #8c9eff !important;
}

.bg-indigo.accent-2 {
	background-color: #536dfe !important;
}

.bg-indigo.accent-3 {
	background-color: #3d5afe !important;
}

.bg-indigo.accent-4 {
	background-color: #304ffe !important;
}

.bg-blue.lighten-5 {
	background-color: #e3f2fd !important;
}

.bg-blue.lighten-4 {
	background-color: #bbdefb !important;
}

.bg-blue.lighten-3 {
	background-color: #90caf9 !important;
}

.bg-blue.lighten-2 {
	background-color: #64b5f6 !important;
}

.bg-blue.lighten-1 {
	background-color: #42a5f5 !important;
}

.bg-blue {
	background-color: #2196f3 !important;
}

.bg-rgba-blue-slight,
.bg-rgba-blue-slight:after {
	background-color: rgba(33, 150, 243, 0.1);
}

.bg-rgba-blue-light,
.bg-rgba-blue-light:after {
	background-color: rgba(33, 150, 243, 0.3);
}

.bg-rgba-blue-strong,
.bg-rgba-blue-strong:after {
	background-color: rgba(33, 150, 243, 0.7);
}

.bg-blue.darken-1 {
	background-color: #1e88e5 !important;
}

.bg-blue.darken-2 {
	background-color: #1976d2 !important;
}

.bg-blue.darken-3 {
	background-color: #1565c0 !important;
}

.bg-blue.darken-4 {
	background-color: #0d47a1 !important;
}

.bg-blue.accent-1 {
	background-color: #82b1ff !important;
}

.bg-blue.accent-2 {
	background-color: #448aff !important;
}

.bg-blue.accent-3 {
	background-color: #2979ff !important;
}

.bg-blue.accent-4 {
	background-color: #2962ff !important;
}

.bg-light-blue.lighten-5 {
	background-color: #e1f5fe !important;
}

.bg-light-blue.lighten-4 {
	background-color: #b3e5fc !important;
}

.bg-light-blue.lighten-3 {
	background-color: #81d4fa !important;
}

.bg-light-blue.lighten-2 {
	background-color: #4fc3f7 !important;
}

.bg-light-blue.lighten-1 {
	background-color: #29b6f6 !important;
}

.bg-light-blue {
	background-color: #03a9f4 !important;
}

.bg-rgba-light-blue-slight,
.bg-rgba-light-blue-slight:after {
	background-color: rgba(3, 169, 244, 0.1);
}

.bg-rgba-light-blue-light,
.bg-rgba-light-blue-light:after {
	background-color: rgba(3, 169, 244, 0.3);
}

.bg-rgba-light-blue-strong,
.bg-rgba-light-blue-strong:after {
	background-color: rgba(3, 169, 244, 0.7);
}

.bg-light-blue.darken-1 {
	background-color: #039be5 !important;
}

.bg-light-blue.darken-2 {
	background-color: #0288d1 !important;
}

.bg-light-blue.darken-3 {
	background-color: #0277bd !important;
}

.bg-light-blue.darken-4 {
	background-color: #01579b !important;
}

.bg-light-blue.accent-1 {
	background-color: #80d8ff !important;
}

.bg-light-blue.accent-2 {
	background-color: #40c4ff !important;
}

.bg-light-blue.accent-3 {
	background-color: #00b0ff !important;
}

.bg-light-blue.accent-4 {
	background-color: #0091ea !important;
}

.bg-cyan.lighten-5 {
	background-color: #e0f7fa !important;
}

.bg-cyan.lighten-4 {
	background-color: #b2ebf2 !important;
}

.bg-cyan.lighten-3 {
	background-color: #80deea !important;
}

.bg-cyan.lighten-2 {
	background-color: #4dd0e1 !important;
}

.bg-cyan.lighten-1 {
	background-color: #26c6da !important;
}

.bg-cyan {
	background-color: #00bcd4 !important;
}

.bg-rgba-cyan-slight,
.bg-rgba-cyan-slight:after {
	background-color: rgba(0, 188, 212, 0.1);
}

.bg-rgba-cyan-light,
.bg-rgba-cyan-light:after {
	background-color: rgba(0, 188, 212, 0.3);
}

.bg-rgba-cyan-strong,
.bg-rgba-cyan-strong:after {
	background-color: rgba(0, 188, 212, 0.7);
}

.bg-cyan.darken-1 {
	background-color: #00acc1 !important;
}

.bg-cyan.darken-2 {
	background-color: #0097a7 !important;
}

.bg-cyan.darken-3 {
	background-color: #00838f !important;
}

.bg-cyan.darken-4 {
	background-color: #006064 !important;
}

.bg-cyan.accent-1 {
	background-color: #84ffff !important;
}

.bg-cyan.accent-2 {
	background-color: #18ffff !important;
}

.bg-cyan.accent-3 {
	background-color: #00e5ff !important;
}

.bg-cyan.accent-4 {
	background-color: #00b8d4 !important;
}

.bg-teal.lighten-5 {
	background-color: #e0f2f1 !important;
}

.bg-teal.lighten-4 {
	background-color: #b2dfdb !important;
}

.bg-teal.lighten-3 {
	background-color: #80cbc4 !important;
}

.bg-teal.lighten-2 {
	background-color: #4db6ac !important;
}

.bg-teal.lighten-1 {
	background-color: #26a69a !important;
}

.bg-teal {
	background-color: #009688 !important;
}

.bg-rgba-teal-slight,
.bg-rgba-teal-slight:after {
	background-color: rgba(0, 150, 136, 0.1);
}

.bg-rgba-teal-light,
.bg-rgba-teal-light:after {
	background-color: rgba(0, 150, 136, 0.3);
}

.bg-rgba-teal-strong,
.bg-rgba-teal-strong:after {
	background-color: rgba(0, 150, 136, 0.7);
}

.bg-teal.darken-1 {
	background-color: #00897b !important;
}

.bg-teal.darken-2 {
	background-color: #00796b !important;
}

.bg-teal.darken-3 {
	background-color: #00695c !important;
}

.bg-teal.darken-4 {
	background-color: #004d40 !important;
}

.bg-teal.accent-1 {
	background-color: #a7ffeb !important;
}

.bg-teal.accent-2 {
	background-color: #64ffda !important;
}

.bg-teal.accent-3 {
	background-color: #1de9b6 !important;
}

.bg-teal.accent-4 {
	background-color: #00bfa5 !important;
}

.bg-green.lighten-5 {
	background-color: #e8f5e9 !important;
}

.bg-green.lighten-4 {
	background-color: #c8e6c9 !important;
}

.bg-green.lighten-3 {
	background-color: #a5d6a7 !important;
}

.bg-green.lighten-2 {
	background-color: #81c784 !important;
}

.bg-green.lighten-1 {
	background-color: #66bb6a !important;
}

.bg-green {
	background-color: #4caf50 !important;
}

.bg-rgba-green-slight,
.bg-rgba-green-slight:after {
	background-color: rgba(76, 175, 80, 0.1);
}

.bg-rgba-green-light,
.bg-rgba-green-light:after {
	background-color: rgba(76, 175, 80, 0.3);
}

.bg-rgba-green-strong,
.bg-rgba-green-strong:after {
	background-color: rgba(76, 175, 80, 0.7);
}

.bg-green.darken-1 {
	background-color: #43a047 !important;
}

.bg-green.darken-2 {
	background-color: #388e3c !important;
}

.bg-green.darken-3 {
	background-color: #2e7d32 !important;
}

.bg-green.darken-4 {
	background-color: #1b5e20 !important;
}

.bg-green.accent-1 {
	background-color: #b9f6ca !important;
}

.bg-green.accent-2 {
	background-color: #69f0ae !important;
}

.bg-green.accent-3 {
	background-color: #00e676 !important;
}

.bg-green.accent-4 {
	background-color: #00c853 !important;
}

.bg-light-green.lighten-5 {
	background-color: #f1f8e9 !important;
}

.bg-light-green.lighten-4 {
	background-color: #dcedc8 !important;
}

.bg-light-green.lighten-3 {
	background-color: #c5e1a5 !important;
}

.bg-light-green.lighten-2 {
	background-color: #aed581 !important;
}

.bg-light-green.lighten-1 {
	background-color: #9ccc65 !important;
}

.bg-light-green {
	background-color: #8bc34a !important;
}

.bg-rgba-light-green-slight,
.bg-rgba-light-green-slight:after {
	background-color: rgba(139, 195, 74, 0.1);
}

.bg-rgba-light-green-light,
.bg-rgba-light-green-light:after {
	background-color: rgba(139, 195, 74, 0.3);
}

.bg-rgba-light-green-strong,
.bg-rgba-light-green-strong:after {
	background-color: rgba(139, 195, 74, 0.7);
}

.bg-light-green.darken-1 {
	background-color: #7cb342 !important;
}

.bg-light-green.darken-2 {
	background-color: #689f38 !important;
}

.bg-light-green.darken-3 {
	background-color: #558b2f !important;
}

.bg-light-green.darken-4 {
	background-color: #33691e !important;
}

.bg-light-green.accent-1 {
	background-color: #ccff90 !important;
}

.bg-light-green.accent-2 {
	background-color: #b2ff59 !important;
}

.bg-light-green.accent-3 {
	background-color: #76ff03 !important;
}

.bg-light-green.accent-4 {
	background-color: #64dd17 !important;
}

.bg-lime.lighten-5 {
	background-color: #f9fbe7 !important;
}

.bg-lime.lighten-4 {
	background-color: #f0f4c3 !important;
}

.bg-lime.lighten-3 {
	background-color: #e6ee9c !important;
}

.bg-lime.lighten-2 {
	background-color: #dce775 !important;
}

.bg-lime.lighten-1 {
	background-color: #d4e157 !important;
}

.bg-lime {
	background-color: #cddc39 !important;
}


.bg-rgba-lime-slight,
.bg-rgba-lime-slight:after {
	background-color: rgba(205, 220, 57, 0.1);
}

.bg-rgba-lime-light,
.bg-rgba-lime-light:after {
	background-color: rgba(205, 220, 57, 0.3);
}

.bg-rgba-lime-strong,
.bg-rgba-lime-strong:after {
	background-color: rgba(205, 220, 57, 0.7);
}

.bg-lime.darken-1 {
	background-color: #c0ca33 !important;
}

.bg-lime.darken-2 {
	background-color: #afb42b !important;
}

.bg-lime.darken-3 {
	background-color: #9e9d24 !important;
}

.bg-lime.darken-4 {
	background-color: #827717 !important;
}

.bg-lime.accent-1 {
	background-color: #f4ff81 !important;
}

.bg-lime.accent-2 {
	background-color: #eeff41 !important;
}

.bg-lime.accent-3 {
	background-color: #c6ff00 !important;
}

.bg-lime.accent-4 {
	background-color: #aeea00 !important;
}

.bg-yellow.lighten-5 {
	background-color: #fffde7 !important;
}

.bg-yellow.lighten-4 {
	background-color: #fff9c4 !important;
}

.bg-yellow.lighten-3 {
	background-color: #fff59d !important;
}

.bg-yellow.lighten-2 {
	background-color: #fff176 !important;
}

.bg-yellow.lighten-1 {
	background-color: #ffee58 !important;
}

.bg-yellow {
	background-color: #ffeb3b !important;
}

.bg-rgba-yellow-slight,
.bg-rgba-yellow-slight:after {
	background-color: rgba(255, 235, 59, 0.1);
}

.bg-rgba-yellow-light,
.bg-rgba-yellow-light:after {
	background-color: rgba(255, 235, 59, 0.3);
}

.bg-rgba-yellow-strong,
.bg-rgba-yellow-strong:after {
	background-color: rgba(255, 235, 59, 0.7);
}

.bg-yellow.darken-1 {
	background-color: #fdd835 !important;
}

.bg-yellow.darken-2 {
	background-color: #fbc02d !important;
}

.bg-yellow.darken-3 {
	background-color: #f9a825 !important;
}

.bg-yellow.darken-4 {
	background-color: #f57f17 !important;
}

.bg-yellow.accent-1 {
	background-color: #ffff8d !important;
}

.bg-yellow.accent-2 {
	background-color: #ffff00 !important;
}

.bg-yellow.accent-3 {
	background-color: #ffea00 !important;
}

.bg-yellow.accent-4 {
	background-color: #ffd600 !important;
}

.bg-amber.lighten-5 {
	background-color: #fff8e1 !important;
}

.bg-amber.lighten-4 {
	background-color: #ffecb3 !important;
}

.bg-amber.lighten-3 {
	background-color: #ffe082 !important;
}

.bg-amber.lighten-2 {
	background-color: #ffd54f !important;
}

.bg-amber.lighten-1 {
	background-color: #ffca28 !important;
}

.bg-amber {
	background-color: #ffc107 !important;
}

.bg-rgba-amber-slight,
.bg-rgba-amber-slight:after {
	background-color: rgba(255, 193, 7, 0.1);
}

.bg-rgba-amber-light,
.bg-rgba-amber-light:after {
	background-color: rgba(255, 193, 7, 0.3);
}

.bg-rgba-amber-strong,
.bg-rgba-amber-strong:after {
	background-color: rgba(255, 193, 7, 0.7);
}

.bg-amber.darken-1 {
	background-color: #ffb300 !important;
}

.bg-amber.darken-2 {
	background-color: #ffa000 !important;
}

.bg-amber.darken-3 {
	background-color: #ff8f00 !important;
}

.bg-amber.darken-4 {
	background-color: #ff6f00 !important;
}

.bg-amber.accent-1 {
	background-color: #ffe57f !important;
}

.bg-amber.accent-2 {
	background-color: #ffd740 !important;
}

.bg-amber.accent-3 {
	background-color: #ffc400 !important;
}

.bg-amber.accent-4 {
	background-color: #ffab00 !important;
}

.bg-orange.lighten-5 {
	background-color: #fff3e0 !important;
}

.bg-orange.lighten-4 {
	background-color: #ffe0b2 !important;
}

.bg-orange.lighten-3 {
	background-color: #ffcc80 !important;
}

.bg-orange.lighten-2 {
	background-color: #ffb74d !important;
}

.bg-orange.lighten-1 {
	background-color: #ffa726 !important;
}

.bg-orange {
	background-color: #ff9800 !important;
}

.bg-rgba-orange-slight,
.bg-rgba-orange-slight:after {
	background-color: rgba(255, 152, 0, 0.1);
}

.bg-rgba-orange-light,
.bg-rgba-orange-light:after {
	background-color: rgba(255, 152, 0, 0.3);
}

.bg-rgba-orange-strong,
.bg-rgba-orange-strong:after {
	background-color: rgba(255, 152, 0, 0.7);
}

.bg-orange.darken-1 {
	background-color: #fb8c00 !important;
}

.bg-orange.darken-2 {
	background-color: #f57c00 !important;
}

.bg-orange.darken-3 {
	background-color: #ef6c00 !important;
}

.bg-orange.darken-4 {
	background-color: #e65100 !important;
}

.bg-orange.accent-1 {
	background-color: #ffd180 !important;
}

.bg-orange.accent-2 {
	background-color: #ffab40 !important;
}

.bg-orange.accent-3 {
	background-color: #ff9100 !important;
}

.bg-orange.accent-4 {
	background-color: #ff6d00 !important;
}

.bg-deep-orange.lighten-5 {
	background-color: #fbe9e7 !important;
}

.bg-deep-orange.lighten-4 {
	background-color: #ffccbc !important;
}

.bg-deep-orange.lighten-3 {
	background-color: #ffab91 !important;
}

.bg-deep-orange.lighten-2 {
	background-color: #ff8a65 !important;
}

.bg-deep-orange.lighten-1 {
	background-color: #ff7043 !important;
}

.bg-deep-orange {
	background-color: #ff5722 !important;
}

.bg-rgba-deep-orange-slight,
.bg-rgba-deep-orange-slight:after {
	background-color: rgba(255, 87, 34, 0.1);
}

.bg-rgba-deep-orange-light,
.bg-rgba-deep-orange-light:after {
	background-color: rgba(255, 87, 34, 0.3);
}

.bg-rgba-deep-orange-strong,
.bg-rgba-deep-orange-strong:after {
	background-color: rgba(255, 87, 34, 0.7);
}

.bg-deep-orange.darken-1 {
	background-color: #f4511e !important;
}

.bg-deep-orange.darken-2 {
	background-color: #e64a19 !important;
}

.bg-deep-orange.darken-3 {
	background-color: #d84315 !important;
}

.bg-deep-orange.darken-4 {
	background-color: #bf360c !important;
}

.bg-deep-orange.accent-1 {
	background-color: #ff9e80 !important;
}

.bg-deep-orange.accent-2 {
	background-color: #ff6e40 !important;
}

.bg-deep-orange.accent-3 {
	background-color: #ff3d00 !important;
}

.bg-deep-orange.accent-4 {
	background-color: #dd2c00 !important;
}

.bg-brown.lighten-5 {
	background-color: #efebe9 !important;
}

.bg-brown.lighten-4 {
	background-color: #d7ccc8 !important;
}

.bg-brown.lighten-3 {
	background-color: #bcaaa4 !important;
}

.bg-brown.lighten-2 {
	background-color: #a1887f !important;
}

.bg-brown.lighten-1 {
	background-color: #8d6e63 !important;
}

.bg-brown {
	background-color: #795548 !important;
}

.bg-rgba-brown-slight,
.bg-rgba-brown-slight:after {
	background-color: rgba(121, 85, 72, 0.1);
}

.bg-rgba-brown-light,
.bg-rgba-brown-light:after {
	background-color: rgba(121, 85, 72, 0.3);
}

.bg-rgba-brown-strong,
.bg-rgba-brown-strong:after {
	background-color: rgba(121, 85, 72, 0.7);
}

.bg-brown.darken-1 {
	background-color: #6d4c41 !important;
}

.bg-brown.darken-2 {
	background-color: #5d4037 !important;
}

.bg-brown.darken-3 {
	background-color: #4e342e !important;
}

.bg-brown.darken-4 {
	background-color: #3e2723 !important;
}

.bg-blue-grey.lighten-5 {
	background-color: #eceff1 !important;
}

.bg-blue-grey.lighten-4 {
	background-color: #cfd8dc !important;
}

.bg-blue-grey.lighten-3 {
	background-color: #b0bec5 !important;
}

.bg-blue-grey.lighten-2 {
	background-color: #90a4ae !important;
}

.bg-blue-grey.lighten-1 {
	background-color: #78909c !important;
}

.bg-blue-grey {
	background-color: #607d8b !important;
}

.bg-rgba-blue-grey-slight,
.bg-rgba-blue-grey-slight:after {
	background-color: rgba(96, 125, 139, 0.1);
}

.bg-rgba-blue-grey-light,
.bg-rgba-blue-grey-light:after {
	background-color: rgba(96, 125, 139, 0.3);
}

.bg-rgba-blue-grey-strong,
.bg-rgba-blue-grey-strong:after {
	background-color: rgba(96, 125, 139, 0.7);
}

.bg-blue-grey.darken-1 {
	background-color: #546e7a !important;
}

.bg-blue-grey.darken-2 {
	background-color: #455a64 !important;
}

.bg-blue-grey.darken-3 {
	background-color: #37474f !important;
}

.bg-blue-grey.darken-4 {
	background-color: #263238 !important;
}

.bg-grey.lighten-5 {
	background-color: #fafafa !important;
}

.bg-grey.lighten-4 {
	background-color: #f5f5f5 !important;
}

.bg-grey.lighten-3 {
	background-color: #eeeeee !important;
}

.bg-grey.lighten-2 {
	background-color: #e0e0e0 !important;
}

.bg-grey.lighten-1 {
	background-color: #bdbdbd !important;
}

.bg-grey {
	background-color: #9e9e9e !important;
}

.bg-rgba-grey-slight,
.bg-rgba-grey-slight:after {
	background-color: rgba(158, 158, 158, 0.1);
}

.bg-rgba-grey-light,
.bg-md-pills .nav-link:hover,
.bg-rgba-grey-light:after,
.bg-md-pills .nav-link:hover:after {
	background-color: rgba(158, 158, 158, 0.3);
}

.bg-rgba-grey-strong,
.bg-rgba-grey-strong:after {
	background-color: rgba(158, 158, 158, 0.7);
}

.bg-grey.darken-1 {
	background-color: #757575 !important;
}

.bg-grey.darken-2 {
	background-color: #616161 !important;
}

.bg-grey.darken-3 {
	background-color: #424242 !important;
}

.bg-grey.darken-4 {
	background-color: #212121 !important;
}

.bg-black {
	background-color: #000 !important;
}

.bg-rgba-black-slight,
.bg-rgba-black-slight:after {
	background-color: rgba(0, 0, 0, 0.1);
}

.bg-rgba-black-light,
.bg-rgba-black-light:after {
	background-color: rgba(0, 0, 0, 0.3);
}

.bg-rgba-black-strong,
.bg-rgba-black-strong:after {
	background-color: rgba(0, 0, 0, 0.7);
}

.bg-white {
	background-color: #fff !important;
}

.bg-rgba-white-slight,
.bg-rgba-white-slight:after {
	background-color: rgba(255, 255, 255, 0.1);
}

.bg-rgba-white-light,
.bg-rgba-white-light:after {
	background-color: rgba(255, 255, 255, 0.3);
}

.bg-rgba-white-strong,
.bg-rgba-white-strong:after {
	background-color: rgba(255, 255, 255, 0.7);
}

.bg-rgba-stylish-slight {
	background-color: rgba(62, 69, 81, 0.1);
}

.bg-rgba-stylish-light {
	background-color: rgba(62, 69, 81, 0.3);
}

.bg-rgba-stylish-strong {
	background-color: rgba(62, 69, 81, 0.7);
}

.bg-elegant-color {
	background-color: #2e2e2e !important;
}

.bg-elegant-color-dark {
	background-color: #212121 !important;
}

.bg-stylish-color {
	background-color: #4b515d !important;
}

.bg-stylish-color-dark {
	background-color: #3e4551 !important;
}

.bg-unique-color {
	background-color: #3f729b !important;
}

.bg-unique-color-dark {
	background-color: #1c2331 !important;
}

.bg-special-color {
	background-color: #37474f !important;
}

.bg-special-color-dark {
	background-color: #263238 !important;
}

.bg-primary {
	background-color: if(function-exists(paper-color), paper-color($primary), color($primary)) !important;
}

.bg-accent {
	background-color: if(function-exists(paper-color), paper-color($accent), color($accent)) !important;
}

.bg-warn {
	background-color: if(function-exists(paper-color), paper-color($warn), color($warn)) !important;
}

.bg-light {
	background-color: $light-contrast-color !important;
}

.bg-dark {
	background-color: $dark-contrast-color !important;
}

/* Gradients */
.bg-purple-gradient {
	background: linear-gradient(40deg, #ff6ec4, #7873f5) !important;
}

.bg-peach-gradient {
	background: linear-gradient(40deg, #FFD86F, #FC6262) !important;
}

.bg-aqua-gradient {
	background: linear-gradient(40deg, #2096ff, #05ffa3) !important;
}

.bg-blue-gradient {
	background: linear-gradient(40deg, #45cafc, #303f9f) !important;
}

.bg-purple-gradient-rgba {
	background: linear-gradient(40deg, rgba(255, 110, 196, 0.9), rgba(120, 115, 245, 0.9)) !important;
}

.bg-peach-gradient-rgba {
	background: linear-gradient(40deg, rgba(255, 216, 111, 0.9), rgba(252, 98, 98, 0.9)) !important;
}

.bg-aqua-gradient-rgba {
	background: linear-gradient(40deg, rgba(32, 150, 255, 0.9), rgba(5, 255, 163, 0.9)) !important;
}

.bg-blue-gradient-rgba {
	background: linear-gradient(40deg, rgba(69, 202, 252, 0.9), rgba(48, 63, 159, 0.9)) !important;
}

.bg-juicy-peach-gradient {
	background-image: linear-gradient(to right, #ffecd2 0%, #fcb69f 100%);
}

.bg-young-passion-gradient {
	background-image: linear-gradient(to right, #ff8177 0%, #ff867a 0%, #ff8c7f 21%, #f99185 52%, #cf556c 78%, #b12a5b 100%);
}

.bg-lady-lips-gradient {
	background-image: linear-gradient(to top, #ff9a9e 0%, #fecfef 99%, #fecfef 100%);
}

.bg-sunny-morning-gradient {
	background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
}

.bg-rainy-ashville-gradient {
	background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
}

.bg-frozen-dreams-gradient {
	background-image: linear-gradient(to top, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%);
}

.bg-warm-flame-gradient {
	background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
}

.bg-night-fade-gradient {
	background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);
}

.bg-spring-warmth-gradient {
	background-image: linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%);
}

.bg-winter-neva-gradient {
	background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}

.bg-dusty-grass-gradient {
	background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
}

.bg-tempting-azure-gradient {
	background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.bg-heavy-rain-gradient {
	background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}

.bg-amy-crisp-gradient {
	background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
}

.bg-mean-fruit-gradient {
	background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
}

.bg-deep-blue-gradient {
	background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
}

.bg-ripe-malinka-gradient {
	background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%);
}

.bg-cloudy-knoxville-gradient {
	background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

.bg-morpheus-den-gradient {
	background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);
}

.bg-rare-wind-gradient {
	background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
}

.bg-near-moon-gradient {
	background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%);
}
