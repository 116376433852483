@import 'utilities/bg-colors';
@import 'utilities/bg-patterns';

@import 'utilities/margins';
@import 'utilities/borders';
@import 'utilities/border-colors';
@import 'utilities/padding';

@import 'utilities/dimensions';
@import 'utilities/overflow';
@import 'utilities/shadows';

@import 'utilities/text';
