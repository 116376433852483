.md-table-pagination {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap-reverse;
	box-sizing: border-box;
	padding: 0 24px;
	font-size: 12px;
	color: rgba(0, 0, 0, 0.54);
	border-top: 1px rgba(0, 0, 0, 12%);

	md-select {
		justify-content: flex-end;
		min-width: 64px;

		&:not([disabled]):focus .md-select-value {
			color: rgba(0, 0, 0, 0.54);
		}

		.md-select-value {
			flex: 0 0 auto;

			span.md-select-icon {
				justify-content: center;
				text-align: center;
				margin-right: -6px !important;

				&:after {
					top: initial;
					transform: scaleY(0.5) scaleX(1);
				}
			}
		}
	}

	>* {
		display: flex;
		align-items: center;
		height: 56px;
	}

	>.buttons:not(:first-child),
	>.limit-select:not(:first-child) {
		margin-left: 32px;
	}

	>.buttons {
		margin-right: -16px;

		>.md-button.md-icon-button {
			margin: 0;
		}

		>.md-table-label+.md-button.md-icon-button {
			margin-left: 20px;
		}
	}
}
