.br-mdb-color.lighten-5 {
	border-color: #d0d6e2 !important;
}

.br-mdb-color.lighten-4 {
	border-color: #b1bace !important;
}

.br-mdb-color.lighten-3 {
	border-color: #929fba !important;
}

.br-mdb-color.lighten-2 {
	border-color: #7283a7 !important;
}

.br-mdb-color.lighten-1 {
	border-color: #59698d !important;
}

.br-mdb-color {
	border-color: #45526e !important;
}

.br-rgba-mdb-color-slight,
.br-rgba-mdb-color-slight:after {
	border-color: rgba(69, 82, 110, 0.1);
}

.br-rgba-mdb-color-light,
.br-rgba-mdb-color-light:after {
	border-color: rgba(69, 82, 110, 0.3);
}

.br-rgba-mdb-color-strong,
.br-rgba-mdb-color-strong:after {
	border-color: rgba(69, 82, 110, 0.7);
}

.br-mdb-color.darken-1 {
	border-color: #3b465e !important;
}

.br-mdb-color.darken-2 {
	border-color: #2e3951 !important;
}

.br-mdb-color.darken-3 {
	border-color: #1c2a48 !important;
}

.br-mdb-color.darken-4 {
	border-color: #1c2331 !important;
}

.br-red.lighten-5 {
	border-color: #ffebee !important;
}

.br-red.lighten-4 {
	border-color: #ffcdd2 !important;
}

.br-red.lighten-3 {
	border-color: #ef9a9a !important;
}

.br-red.lighten-2 {
	border-color: #e57373 !important;
}

.br-red.lighten-1 {
	border-color: #ef5350 !important;
}

.br-red {
	border-color: #f44336 !important;
}

.br-rgba-red-slight,
.br-rgba-red-slight:after {
	border-color: rgba(244, 67, 54, 0.1);
}

.br-rgba-red-light,
.br-rgba-red-light:after {
	border-color: rgba(244, 67, 54, 0.3);
}

.br-rgba-red-strong,
.br-rgba-red-strong:after {
	border-color: rgba(244, 67, 54, 0.7);
}

.br-red.darken-1 {
	border-color: #e53935 !important;
}

.br-red.darken-2 {
	border-color: #d32f2f !important;
}

.br-red.darken-3 {
	border-color: #c62828 !important;
}

.br-red.darken-4 {
	border-color: #b71c1c !important;
}

.br-red.accent-1 {
	border-color: #ff8a80 !important;
}

.br-red.accent-2 {
	border-color: #ff5252 !important;
}

.br-red.accent-3 {
	border-color: #ff1744 !important;
}

.br-red.accent-4 {
	border-color: #d50000 !important;
}

.br-pink.lighten-5 {
	border-color: #fce4ec !important;
}

.br-pink.lighten-4 {
	border-color: #f8bbd0 !important;
}

.br-pink.lighten-3 {
	border-color: #f48fb1 !important;
}

.br-pink.lighten-2 {
	border-color: #f06292 !important;
}

.br-pink.lighten-1 {
	border-color: #ec407a !important;
}

.br-pink {
	border-color: #e91e63 !important;
}

.br-rgba-pink-slight,
.br-rgba-pink-slight:after {
	border-color: rgba(233, 30, 99, 0.1);
}

.br-rgba-pink-light,
.br-rgba-pink-light:after {
	border-color: rgba(233, 30, 99, 0.3);
}

.br-rgba-pink-strong,
.br-rgba-pink-strong:after {
	border-color: rgba(233, 30, 99, 0.7);
}

.br-pink.darken-1 {
	border-color: #d81b60 !important;
}

.br-pink.darken-2 {
	border-color: #c2185b !important;
}

.br-pink.darken-3 {
	border-color: #ad1457 !important;
}

.br-pink.darken-4 {
	border-color: #880e4f !important;
}

.br-pink.accent-1 {
	border-color: #ff80ab !important;
}

.br-pink.accent-2 {
	border-color: #ff4081 !important;
}

.br-pink.accent-3 {
	border-color: #f50057 !important;
}

.br-pink.accent-4 {
	border-color: #c51162 !important;
}

.br-purple.lighten-5 {
	border-color: #f3e5f5 !important;
}

.br-purple.lighten-4 {
	border-color: #e1bee7 !important;
}

.br-purple.lighten-3 {
	border-color: #ce93d8 !important;
}

.br-purple.lighten-2 {
	border-color: #ba68c8 !important;
}

.br-purple.lighten-1 {
	border-color: #ab47bc !important;
}

.br-purple {
	border-color: #9c27b0 !important;
}

.br-rgba-purple-slight,
.br-rgba-purple-slight:after {
	border-color: rgba(156, 39, 176, 0.1);
}

.br-rgba-purple-light,
.br-rgba-purple-light:after {
	border-color: rgba(156, 39, 176, 0.3);
}

.br-rgba-purple-strong,
.br-rgba-purple-strong:after {
	border-color: rgba(156, 39, 176, 0.7);
}

.br-purple.darken-1 {
	border-color: #8e24aa !important;
}

.br-purple.darken-2 {
	border-color: #7b1fa2 !important;
}

.br-purple.darken-3 {
	border-color: #6a1b9a !important;
}

.br-purple.darken-4 {
	border-color: #4a148c !important;
}

.br-purple.accent-1 {
	border-color: #ea80fc !important;
}

.br-purple.accent-2 {
	border-color: #e040fb !important;
}

.br-purple.accent-3 {
	border-color: #d500f9 !important;
}

.br-purple.accent-4 {
	border-color: #aa00ff !important;
}

.br-deep-purple.lighten-5 {
	border-color: #ede7f6 !important;
}

.br-deep-purple.lighten-4 {
	border-color: #d1c4e9 !important;
}

.br-deep-purple.lighten-3 {
	border-color: #b39ddb !important;
}

.br-deep-purple.lighten-2 {
	border-color: #9575cd !important;
}

.br-deep-purple.lighten-1 {
	border-color: #7e57c2 !important;
}

.br-deep-purple {
	border-color: #673ab7 !important;
}

.br-rgba-deep-purple-slight,
.br-rgba-deep-purple-slight:after {
	border-color: rgba(103, 58, 183, 0.1);
}

.br-rgba-deep-purple-light,
.br-rgba-deep-purple-light:after {
	border-color: rgba(103, 58, 183, 0.3);
}

.br-rgba-deep-purple-strong,
.br-rgba-deep-purple-strong:after {
	border-color: rgba(103, 58, 183, 0.7);
}

.br-deep-purple.darken-1 {
	border-color: #5e35b1 !important;
}

.br-deep-purple.darken-2 {
	border-color: #512da8 !important;
}

.br-deep-purple.darken-3 {
	border-color: #4527a0 !important;
}

.br-deep-purple.darken-4 {
	border-color: #311b92 !important;
}

.br-deep-purple.accent-1 {
	border-color: #b388ff !important;
}

.br-deep-purple.accent-2 {
	border-color: #7c4dff !important;
}

.br-deep-purple.accent-3 {
	border-color: #651fff !important;
}

.br-deep-purple.accent-4 {
	border-color: #6200ea !important;
}

.br-indigo.lighten-5 {
	border-color: #e8eaf6 !important;
}

.br-indigo.lighten-4 {
	border-color: #c5cae9 !important;
}

.br-indigo.lighten-3 {
	border-color: #9fa8da !important;
}

.br-indigo.lighten-2 {
	border-color: #7986cb !important;
}

.br-indigo.lighten-1 {
	border-color: #5c6bc0 !important;
}

.br-indigo {
	border-color: #3f51b5 !important;
}

.br-rgba-indigo-slight,
.br-rgba-indigo-slight:after {
	border-color: rgba(63, 81, 181, 0.1);
}

.br-rgba-indigo-light,
.br-rgba-indigo-light:after {
	border-color: rgba(63, 81, 181, 0.3);
}

.br-rgba-indigo-strong,
.br-rgba-indigo-strong:after {
	border-color: rgba(63, 81, 181, 0.7);
}

.br-indigo.darken-1 {
	border-color: #3949ab !important;
}

.br-indigo.darken-2 {
	border-color: #303f9f !important;
}

.br-indigo.darken-3 {
	border-color: #283593 !important;
}

.br-indigo.darken-4 {
	border-color: #1a237e !important;
}

.br-indigo.accent-1 {
	border-color: #8c9eff !important;
}

.br-indigo.accent-2 {
	border-color: #536dfe !important;
}

.br-indigo.accent-3 {
	border-color: #3d5afe !important;
}

.br-indigo.accent-4 {
	border-color: #304ffe !important;
}

.br-blue.lighten-5 {
	border-color: #e3f2fd !important;
}

.br-blue.lighten-4 {
	border-color: #bbdefb !important;
}

.br-blue.lighten-3 {
	border-color: #90caf9 !important;
}

.br-blue.lighten-2 {
	border-color: #64b5f6 !important;
}

.br-blue.lighten-1 {
	border-color: #42a5f5 !important;
}

.br-blue {
	border-color: #2196f3 !important;
}

.br-rgba-blue-slight,
.br-rgba-blue-slight:after {
	border-color: rgba(33, 150, 243, 0.1);
}

.br-rgba-blue-light,
.br-rgba-blue-light:after {
	border-color: rgba(33, 150, 243, 0.3);
}

.br-rgba-blue-strong,
.br-rgba-blue-strong:after {
	border-color: rgba(33, 150, 243, 0.7);
}

.br-blue.darken-1 {
	border-color: #1e88e5 !important;
}

.br-blue.darken-2 {
	border-color: #1976d2 !important;
}

.br-blue.darken-3 {
	border-color: #1565c0 !important;
}

.br-blue.darken-4 {
	border-color: #0d47a1 !important;
}

.br-blue.accent-1 {
	border-color: #82b1ff !important;
}

.br-blue.accent-2 {
	border-color: #448aff !important;
}

.br-blue.accent-3 {
	border-color: #2979ff !important;
}

.br-blue.accent-4 {
	border-color: #2962ff !important;
}

.br-light-blue.lighten-5 {
	border-color: #e1f5fe !important;
}

.br-light-blue.lighten-4 {
	border-color: #b3e5fc !important;
}

.br-light-blue.lighten-3 {
	border-color: #81d4fa !important;
}

.br-light-blue.lighten-2 {
	border-color: #4fc3f7 !important;
}

.br-light-blue.lighten-1 {
	border-color: #29b6f6 !important;
}

.br-light-blue {
	border-color: #03a9f4 !important;
}

.br-rgba-light-blue-slight,
.br-rgba-light-blue-slight:after {
	border-color: rgba(3, 169, 244, 0.1);
}

.br-rgba-light-blue-light,
.br-rgba-light-blue-light:after {
	border-color: rgba(3, 169, 244, 0.3);
}

.br-rgba-light-blue-strong,
.br-rgba-light-blue-strong:after {
	border-color: rgba(3, 169, 244, 0.7);
}

.br-light-blue.darken-1 {
	border-color: #039be5 !important;
}

.br-light-blue.darken-2 {
	border-color: #0288d1 !important;
}

.br-light-blue.darken-3 {
	border-color: #0277bd !important;
}

.br-light-blue.darken-4 {
	border-color: #01579b !important;
}

.br-light-blue.accent-1 {
	border-color: #80d8ff !important;
}

.br-light-blue.accent-2 {
	border-color: #40c4ff !important;
}

.br-light-blue.accent-3 {
	border-color: #00b0ff !important;
}

.br-light-blue.accent-4 {
	border-color: #0091ea !important;
}

.br-cyan.lighten-5 {
	border-color: #e0f7fa !important;
}

.br-cyan.lighten-4 {
	border-color: #b2ebf2 !important;
}

.br-cyan.lighten-3 {
	border-color: #80deea !important;
}

.br-cyan.lighten-2 {
	border-color: #4dd0e1 !important;
}

.br-cyan.lighten-1 {
	border-color: #26c6da !important;
}

.br-cyan {
	border-color: #00bcd4 !important;
}

.br-rgba-cyan-slight,
.br-rgba-cyan-slight:after {
	border-color: rgba(0, 188, 212, 0.1);
}

.br-rgba-cyan-light,
.br-rgba-cyan-light:after {
	border-color: rgba(0, 188, 212, 0.3);
}

.br-rgba-cyan-strong,
.br-rgba-cyan-strong:after {
	border-color: rgba(0, 188, 212, 0.7);
}

.br-cyan.darken-1 {
	border-color: #00acc1 !important;
}

.br-cyan.darken-2 {
	border-color: #0097a7 !important;
}

.br-cyan.darken-3 {
	border-color: #00838f !important;
}

.br-cyan.darken-4 {
	border-color: #006064 !important;
}

.br-cyan.accent-1 {
	border-color: #84ffff !important;
}

.br-cyan.accent-2 {
	border-color: #18ffff !important;
}

.br-cyan.accent-3 {
	border-color: #00e5ff !important;
}

.br-cyan.accent-4 {
	border-color: #00b8d4 !important;
}

.br-teal.lighten-5 {
	border-color: #e0f2f1 !important;
}

.br-teal.lighten-4 {
	border-color: #b2dfdb !important;
}

.br-teal.lighten-3 {
	border-color: #80cbc4 !important;
}

.br-teal.lighten-2 {
	border-color: #4db6ac !important;
}

.br-teal.lighten-1 {
	border-color: #26a69a !important;
}

.br-teal {
	border-color: #009688 !important;
}

.br-rgba-teal-slight,
.br-rgba-teal-slight:after {
	border-color: rgba(0, 150, 136, 0.1);
}

.br-rgba-teal-light,
.br-rgba-teal-light:after {
	border-color: rgba(0, 150, 136, 0.3);
}

.br-rgba-teal-strong,
.br-rgba-teal-strong:after {
	border-color: rgba(0, 150, 136, 0.7);
}

.br-teal.darken-1 {
	border-color: #00897b !important;
}

.br-teal.darken-2 {
	border-color: #00796b !important;
}

.br-teal.darken-3 {
	border-color: #00695c !important;
}

.br-teal.darken-4 {
	border-color: #004d40 !important;
}

.br-teal.accent-1 {
	border-color: #a7ffeb !important;
}

.br-teal.accent-2 {
	border-color: #64ffda !important;
}

.br-teal.accent-3 {
	border-color: #1de9b6 !important;
}

.br-teal.accent-4 {
	border-color: #00bfa5 !important;
}

.br-green.lighten-5 {
	border-color: #e8f5e9 !important;
}

.br-green.lighten-4 {
	border-color: #c8e6c9 !important;
}

.br-green.lighten-3 {
	border-color: #a5d6a7 !important;
}

.br-green.lighten-2 {
	border-color: #81c784 !important;
}

.br-green.lighten-1 {
	border-color: #66bb6a !important;
}

.br-green {
	border-color: #4caf50 !important;
}

.br-rgba-green-slight,
.br-rgba-green-slight:after {
	border-color: rgba(76, 175, 80, 0.1);
}

.br-rgba-green-light,
.br-rgba-green-light:after {
	border-color: rgba(76, 175, 80, 0.3);
}

.br-rgba-green-strong,
.br-rgba-green-strong:after {
	border-color: rgba(76, 175, 80, 0.7);
}

.br-green.darken-1 {
	border-color: #43a047 !important;
}

.br-green.darken-2 {
	border-color: #388e3c !important;
}

.br-green.darken-3 {
	border-color: #2e7d32 !important;
}

.br-green.darken-4 {
	border-color: #1b5e20 !important;
}

.br-green.accent-1 {
	border-color: #b9f6ca !important;
}

.br-green.accent-2 {
	border-color: #69f0ae !important;
}

.br-green.accent-3 {
	border-color: #00e676 !important;
}

.br-green.accent-4 {
	border-color: #00c853 !important;
}

.br-light-green.lighten-5 {
	border-color: #f1f8e9 !important;
}

.br-light-green.lighten-4 {
	border-color: #dcedc8 !important;
}

.br-light-green.lighten-3 {
	border-color: #c5e1a5 !important;
}

.br-light-green.lighten-2 {
	border-color: #aed581 !important;
}

.br-light-green.lighten-1 {
	border-color: #9ccc65 !important;
}

.br-light-green {
	border-color: #8bc34a !important;
}

.br-rgba-light-green-slight,
.br-rgba-light-green-slight:after {
	border-color: rgba(139, 195, 74, 0.1);
}

.br-rgba-light-green-light,
.br-rgba-light-green-light:after {
	border-color: rgba(139, 195, 74, 0.3);
}

.br-rgba-light-green-strong,
.br-rgba-light-green-strong:after {
	border-color: rgba(139, 195, 74, 0.7);
}

.br-light-green.darken-1 {
	border-color: #7cb342 !important;
}

.br-light-green.darken-2 {
	border-color: #689f38 !important;
}

.br-light-green.darken-3 {
	border-color: #558b2f !important;
}

.br-light-green.darken-4 {
	border-color: #33691e !important;
}

.br-light-green.accent-1 {
	border-color: #ccff90 !important;
}

.br-light-green.accent-2 {
	border-color: #b2ff59 !important;
}

.br-light-green.accent-3 {
	border-color: #76ff03 !important;
}

.br-light-green.accent-4 {
	border-color: #64dd17 !important;
}

.br-lime.lighten-5 {
	border-color: #f9fbe7 !important;
}

.br-lime.lighten-4 {
	border-color: #f0f4c3 !important;
}

.br-lime.lighten-3 {
	border-color: #e6ee9c !important;
}

.br-lime.lighten-2 {
	border-color: #dce775 !important;
}

.br-lime.lighten-1 {
	border-color: #d4e157 !important;
}

.br-lime {
	border-color: #cddc39 !important;
}

.br-rgba-lime-slight,
.br-rgba-lime-slight:after {
	border-color: rgba(205, 220, 57, 0.1);
}

.br-rgba-lime-light,
.br-rgba-lime-light:after {
	border-color: rgba(205, 220, 57, 0.3);
}

.br-rgba-lime-strong,
.br-rgba-lime-strong:after {
	border-color: rgba(205, 220, 57, 0.7);
}

.br-lime.darken-1 {
	border-color: #c0ca33 !important;
}

.br-lime.darken-2 {
	border-color: #afb42b !important;
}

.br-lime.darken-3 {
	border-color: #9e9d24 !important;
}

.br-lime.darken-4 {
	border-color: #827717 !important;
}

.br-lime.accent-1 {
	border-color: #f4ff81 !important;
}

.br-lime.accent-2 {
	border-color: #eeff41 !important;
}

.br-lime.accent-3 {
	border-color: #c6ff00 !important;
}

.br-lime.accent-4 {
	border-color: #aeea00 !important;
}

.br-yellow.lighten-5 {
	border-color: #fffde7 !important;
}

.br-yellow.lighten-4 {
	border-color: #fff9c4 !important;
}

.br-yellow.lighten-3 {
	border-color: #fff59d !important;
}

.br-yellow.lighten-2 {
	border-color: #fff176 !important;
}

.br-yellow.lighten-1 {
	border-color: #ffee58 !important;
}

.br-yellow {
	border-color: #ffeb3b !important;
}

.br-rgba-yellow-slight,
.br-rgba-yellow-slight:after {
	border-color: rgba(255, 235, 59, 0.1);
}

.br-rgba-yellow-light,
.br-rgba-yellow-light:after {
	border-color: rgba(255, 235, 59, 0.3);
}

.br-rgba-yellow-strong,
.br-rgba-yellow-strong:after {
	border-color: rgba(255, 235, 59, 0.7);
}

.br-yellow.darken-1 {
	border-color: #fdd835 !important;
}

.br-yellow.darken-2 {
	border-color: #fbc02d !important;
}

.br-yellow.darken-3 {
	border-color: #f9a825 !important;
}

.br-yellow.darken-4 {
	border-color: #f57f17 !important;
}

.br-yellow.accent-1 {
	border-color: #ffff8d !important;
}

.br-yellow.accent-2 {
	border-color: #ffff00 !important;
}

.br-yellow.accent-3 {
	border-color: #ffea00 !important;
}

.br-yellow.accent-4 {
	border-color: #ffd600 !important;
}

.br-amber.lighten-5 {
	border-color: #fff8e1 !important;
}

.br-amber.lighten-4 {
	border-color: #ffecb3 !important;
}

.br-amber.lighten-3 {
	border-color: #ffe082 !important;
}

.br-amber.lighten-2 {
	border-color: #ffd54f !important;
}

.br-amber.lighten-1 {
	border-color: #ffca28 !important;
}

.br-amber {
	border-color: #ffc107 !important;
}

.br-rgba-amber-slight,
.br-rgba-amber-slight:after {
	border-color: rgba(255, 193, 7, 0.1);
}

.br-rgba-amber-light,
.br-rgba-amber-light:after {
	border-color: rgba(255, 193, 7, 0.3);
}

.br-rgba-amber-strong,
.br-rgba-amber-strong:after {
	border-color: rgba(255, 193, 7, 0.7);
}

.br-amber.darken-1 {
	border-color: #ffb300 !important;
}

.br-amber.darken-2 {
	border-color: #ffa000 !important;
}

.br-amber.darken-3 {
	border-color: #ff8f00 !important;
}

.br-amber.darken-4 {
	border-color: #ff6f00 !important;
}

.br-amber.accent-1 {
	border-color: #ffe57f !important;
}

.br-amber.accent-2 {
	border-color: #ffd740 !important;
}

.br-amber.accent-3 {
	border-color: #ffc400 !important;
}

.br-amber.accent-4 {
	border-color: #ffab00 !important;
}

.br-orange.lighten-5 {
	border-color: #fff3e0 !important;
}

.br-orange.lighten-4 {
	border-color: #ffe0b2 !important;
}

.br-orange.lighten-3 {
	border-color: #ffcc80 !important;
}

.br-orange.lighten-2 {
	border-color: #ffb74d !important;
}

.br-orange.lighten-1 {
	border-color: #ffa726 !important;
}

.br-orange {
	border-color: #ff9800 !important;
}

.br-rgba-orange-slight,
.br-rgba-orange-slight:after {
	border-color: rgba(255, 152, 0, 0.1);
}

.br-rgba-orange-light,
.br-rgba-orange-light:after {
	border-color: rgba(255, 152, 0, 0.3);
}

.br-rgba-orange-strong,
.br-rgba-orange-strong:after {
	border-color: rgba(255, 152, 0, 0.7);
}

.br-orange.darken-1 {
	border-color: #fb8c00 !important;
}

.br-orange.darken-2 {
	border-color: #f57c00 !important;
}

.br-orange.darken-3 {
	border-color: #ef6c00 !important;
}

.br-orange.darken-4 {
	border-color: #e65100 !important;
}

.br-orange.accent-1 {
	border-color: #ffd180 !important;
}

.br-orange.accent-2 {
	border-color: #ffab40 !important;
}

.br-orange.accent-3 {
	border-color: #ff9100 !important;
}

.br-orange.accent-4 {
	border-color: #ff6d00 !important;
}

.br-deep-orange.lighten-5 {
	border-color: #fbe9e7 !important;
}

.br-deep-orange.lighten-4 {
	border-color: #ffccbc !important;
}

.br-deep-orange.lighten-3 {
	border-color: #ffab91 !important;
}

.br-deep-orange.lighten-2 {
	border-color: #ff8a65 !important;
}

.br-deep-orange.lighten-1 {
	border-color: #ff7043 !important;
}

.br-deep-orange {
	border-color: #ff5722 !important;
}

.br-rgba-deep-orange-slight,
.br-rgba-deep-orange-slight:after {
	border-color: rgba(255, 87, 34, 0.1);
}

.br-rgba-deep-orange-light,
.br-rgba-deep-orange-light:after {
	border-color: rgba(255, 87, 34, 0.3);
}

.br-rgba-deep-orange-strong,
.br-rgba-deep-orange-strong:after {
	border-color: rgba(255, 87, 34, 0.7);
}

.br-deep-orange.darken-1 {
	border-color: #f4511e !important;
}

.br-deep-orange.darken-2 {
	border-color: #e64a19 !important;
}

.br-deep-orange.darken-3 {
	border-color: #d84315 !important;
}

.br-deep-orange.darken-4 {
	border-color: #bf360c !important;
}

.br-deep-orange.accent-1 {
	border-color: #ff9e80 !important;
}

.br-deep-orange.accent-2 {
	border-color: #ff6e40 !important;
}

.br-deep-orange.accent-3 {
	border-color: #ff3d00 !important;
}

.br-deep-orange.accent-4 {
	border-color: #dd2c00 !important;
}

.br-brown.lighten-5 {
	border-color: #efebe9 !important;
}

.br-brown.lighten-4 {
	border-color: #d7ccc8 !important;
}

.br-brown.lighten-3 {
	border-color: #bcaaa4 !important;
}

.br-brown.lighten-2 {
	border-color: #a1887f !important;
}

.br-brown.lighten-1 {
	border-color: #8d6e63 !important;
}

.br-brown {
	border-color: #795548 !important;
}

.br-rgba-brown-slight,
.br-rgba-brown-slight:after {
	border-color: rgba(121, 85, 72, 0.1);
}

.br-rgba-brown-light,
.br-rgba-brown-light:after {
	border-color: rgba(121, 85, 72, 0.3);
}

.br-rgba-brown-strong,
.br-rgba-brown-strong:after {
	border-color: rgba(121, 85, 72, 0.7);
}

.br-brown.darken-1 {
	border-color: #6d4c41 !important;
}

.br-brown.darken-2 {
	border-color: #5d4037 !important;
}

.br-brown.darken-3 {
	border-color: #4e342e !important;
}

.br-brown.darken-4 {
	border-color: #3e2723 !important;
}

.br-blue-grey.lighten-5 {
	border-color: #eceff1 !important;
}

.br-blue-grey.lighten-4 {
	border-color: #cfd8dc !important;
}

.br-blue-grey.lighten-3 {
	border-color: #b0bec5 !important;
}

.br-blue-grey.lighten-2 {
	border-color: #90a4ae !important;
}

.br-blue-grey.lighten-1 {
	border-color: #78909c !important;
}

.br-blue-grey {
	border-color: #607d8b !important;
}

.br-rgba-blue-grey-slight,
.br-rgba-blue-grey-slight:after {
	border-color: rgba(96, 125, 139, 0.1);
}

.br-rgba-blue-grey-light,
.br-rgba-blue-grey-light:after {
	border-color: rgba(96, 125, 139, 0.3);
}

.br-rgba-blue-grey-strong,
.br-rgba-blue-grey-strong:after {
	border-color: rgba(96, 125, 139, 0.7);
}

.br-blue-grey.darken-1 {
	border-color: #546e7a !important;
}

.br-blue-grey.darken-2 {
	border-color: #455a64 !important;
}

.br-blue-grey.darken-3 {
	border-color: #37474f !important;
}

.br-blue-grey.darken-4 {
	border-color: #263238 !important;
}

.br-grey.lighten-5 {
	border-color: #fafafa !important;
}

.br-grey.lighten-4 {
	border-color: #f5f5f5 !important;
}

.br-grey.lighten-3 {
	border-color: #eeeeee !important;
}

.br-grey.lighten-2 {
	border-color: #e0e0e0 !important;
}

.br-grey.lighten-1 {
	border-color: #bdbdbd !important;
}

.br-grey {
	border-color: #9e9e9e !important;
}

.br-rgba-grey-slight,
.br-rgba-grey-slight:after {
	border-color: rgba(158, 158, 158, 0.1);
}

.br-rgba-grey-light,
.br-rgba-grey-light:after {
	border-color: rgba(158, 158, 158, 0.3);
}

.br-rgba-grey-strong,
.br-rgba-grey-strong:after {
	border-color: rgba(158, 158, 158, 0.7);
}

.br-grey.darken-1 {
	border-color: #757575 !important;
}

.br-grey.darken-2 {
	border-color: #616161 !important;
}

.br-grey.darken-3 {
	border-color: #424242 !important;
}

.br-grey.darken-4 {
	border-color: #212121 !important;
}

.br-black {
	border-color: #000 !important;
}

.br-rgba-black-slight,
.br-rgba-black-slight:after {
	border-color: rgba(0, 0, 0, 0.1);
}

.br-rgba-black-light,
.br-rgba-black-light:after {
	border-color: rgba(0, 0, 0, 0.3);
}

.br-rgba-black-strong,
.br-rgba-black-strong:after {
	border-color: rgba(0, 0, 0, 0.7);
}

.br-white {
	border-color: #fff !important;
}

.br-rgba-white-slight,
.br-rgba-white-slight:after {
	border-color: rgba(255, 255, 255, 0.1);
}

.br-rgba-white-light,
.br-rgba-white-light:after {
	border-color: rgba(255, 255, 255, 0.3);
}

.br-rgba-white-strong,
.br-rgba-white-strong:after {
	border-color: rgba(255, 255, 255, 0.7);
}

.br-rgba-stylish-slight {
	border-color: rgba(62, 69, 81, 0.1);
}

.br-rgba-stylish-light {
	border-color: rgba(62, 69, 81, 0.3);
}

.br-rgba-stylish-strong {
	border-color: rgba(62, 69, 81, 0.7);
}

.br-elegant-color {
	border-color: #2e2e2e !important;
}

.br-elegant-color-dark {
	border-color: #212121 !important;
}

.br-stylish-color {
	border-color: #4b515d !important;
}

.br-stylish-color-dark {
	border-color: #3e4551 !important;
}

.br-unique-color {
	border-color: #3f729b !important;
}

.br-unique-color-dark {
	border-color: #1c2331 !important;
}

.br-special-color {
	border-color: #37474f !important;
}

.br-special-color-dark {
	border-color: #263238 !important;
}

.br-primary {
	border-color: if(function-exists(paper-color), paper-color($primary), color($primary)) !important;
}

.br-accent {
	border-color: if(function-exists(paper-color), paper-color($accent), color($accent)) !important;
}

.br-warn {
	border-color: if(function-exists(paper-color), paper-color($warn), color($warn)) !important;
}

.br-light {
	border-color: $light-contrast-color !important;
}

.br-dark {
	border-color: $dark-contrast-color !important;
}
