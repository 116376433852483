/* Width Utilities */
.w-0 {
	width: 0% !important;
}

.w-25 {
	width: 25% !important;
}

.w-50 {
	width: 50% !important;
}

.w-75 {
	width: 75% !important;
}

.w-100 {
	width: 100% !important;
}

.w-auto {
	width: auto !important;
}

/* Max-Width Utilities */
.max-w-0 {
	max-width: 0% !important;
}

.max-w-25 {
	max-width: 25% !important;
}

.max-w-50 {
	max-width: 50% !important;
}

.max-w-75 {
	max-width: 75% !important;
}

.max-w-100 {
	max-width: 100% !important;
}

/* Min-Width Utilities */
.min-w-0 {
	min-width: 0% !important;
}

.min-w-25 {
	min-width: 25% !important;
}

.min-w-50 {
	min-width: 50% !important;
}

.min-w-75 {
	min-width: 75% !important;
}

.min-w-100 {
	min-width: 100% !important;
}

/* Height Utilities */
.h-0 {
	height: 0% !important;
}

.h-25 {
	height: 25% !important;
}

.h-50 {
	height: 50% !important;
}

.h-75 {
	height: 75% !important;
}

.h-100 {
	height: 100% !important;
}

.h-auto {
	height: auto !important;
}

/* Max-Height Utilities */
.max-h-0 {
	max-height: 0% !important;
}

.max-h-25 {
	max-height: 25% !important;
}

.max-h-50 {
	max-height: 50% !important;
}

.max-h-75 {
	max-height: 75% !important;
}

.max-h-100 {
	max-height: 100% !important;
}

/* Min-Height Utilities */
.min-h-0 {
	min-height: 0% !important;
}

.min-h-25 {
	min-height: 25% !important;
}

.min-h-50 {
	min-height: 50% !important;
}

.min-h-75 {
	min-height: 75% !important;
}

.min-h-100 {
	min-height: 100% !important;
}
