// Material Design Color Palette
@import 'color-palette';

// Overriding font & color defaults
$font-family: 'Noto Sans', 'Helvetica Neue', sans-serif;

$default-color: '700';
$primary: $color-blue;

$default-color: '300';
$accent: $color-teal;

$default-color: '700';
$warn: $color-deep-orange;

// Generating the default theme
@import 'default-theme';

// First things first.... reset
@import 'reboot';

// The select theme - has to be first since it imports ember-basic-dropdown
@import "power-select";

// Autogenerated file that imports only the needed styles - see index.js
@import 'pworks-dsl-components';

// Material components
@import 'material-components';

// The simple data table theme
@import 'table';

// Utilities
@import 'utilities';

// Overrides needed for pworks-dsl (avoid if possible)
@import 'overrides/plant-works-icon';
@import 'overrides/plant-works-autocomplete';
@import 'overrides/plant-works-power-select';
@import 'overrides/searchbox';
