.plant-works-power-select-dropdown * {
	box-sizing: border-box;
}

// Trigger
.plant-works-power-select-trigger {
	position: relative;
	border-top: $plant-works-power-select-trigger-border-top;
	border-bottom: $plant-works-power-select-trigger-border-bottom;
	border-right: $plant-works-power-select-trigger-border-right;
	border-left: $plant-works-power-select-trigger-border-left;
	border-radius: $plant-works-power-select-trigger-default-border-radius;
	background-color: $plant-works-power-select-background-color;
	line-height: $plant-works-power-select-line-height;
	overflow-x: hidden;
	text-overflow: ellipsis;

	@if unitless($plant-works-power-select-line-height) {
		min-height: #{$plant-works-power-select-line-height}em;
	}

	@else {
		min-height: $plant-works-power-select-line-height;
	}

	user-select: none;
	-webkit-user-select: none;
	color: $plant-works-power-select-text-color;

	/* Minimum clearfix for modern browsers */
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.plant-works-power-select-trigger:focus,
.plant-works-power-select-trigger--active {
	border-top: $plant-works-power-select-active-trigger-border-top;
	border-bottom: $plant-works-power-select-active-trigger-border-bottom;
	border-right: $plant-works-power-select-active-trigger-border-right;
	border-left: $plant-works-power-select-active-trigger-border-left;
	box-shadow: $plant-works-power-select-focus-box-shadow;

	@if $plant-works-power-select-focus-outline {
		outline: $plant-works-power-select-focus-outline;
	}
}

.ember-basic-dropdown-trigger--below.plant-works-power-select-trigger[aria-expanded="true"],
.ember-basic-dropdown-trigger--in-place.plant-works-power-select-trigger[aria-expanded="true"] {
	border-bottom-left-radius: $plant-works-power-select-opened-border-radius;
	border-bottom-right-radius: $plant-works-power-select-opened-border-radius;
}

.ember-basic-dropdown-trigger--above.plant-works-power-select-trigger[aria-expanded="true"] {
	border-top-left-radius: $plant-works-power-select-opened-border-radius;
	border-top-right-radius: $plant-works-power-select-opened-border-radius;
}

.plant-works-power-select-placeholder {
	color: $plant-works-power-select-placeholder-color;
	display: block;
	overflow-x: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.plant-works-power-select-status-icon {
	position: absolute;
	display: inline-block;
	width: 0;
	height: 0;
	top: 0;
	bottom: 0;
	margin: auto; // Vertical center trick!
	border-style: solid;
	border-width: 7px 4px 0 4px;
	border-color: $plant-works-power-select-trigger-icon-color transparent transparent transparent;

	.ember-basic-dropdown-trigger[aria-expanded="true"] & {
		transform: rotate(180deg);
	}
}

.plant-works-power-select-clear-btn {
	position: absolute;
	cursor: pointer;
}

// Multiple select
.plant-works-power-select-trigger-multiple-input {
	font-family: inherit;
	font-size: inherit;
	border: none;
	display: inline-block;
	line-height: inherit;
	-webkit-appearance: none;
	outline: none;
	padding: 0;
	float: left;
	background-color: transparent;
	text-indent: 2px;

	&:disabled {
		background-color: $plant-works-power-select-disabled-background-color;
	}

	/* There's a browser bug where this selectos cannot be chained with commas */
	&::placeholder {
		opacity: 1;
		color: $plant-works-power-select-placeholder-color;
	}

	&::-webkit-input-placeholder {
		opacity: 1;
		color: $plant-works-power-select-placeholder-color;
	}

	&::-moz-placeholder {
		opacity: 1;
		color: $plant-works-power-select-placeholder-color;
	}

	&::-ms-input-placeholder {
		opacity: 1;
		color: $plant-works-power-select-placeholder-color;
	}
}

.plant-works-power-select-multiple-options {
	padding: 0;
	margin: 0;
}

.plant-works-power-select-multiple-option {
	border: $plant-works-power-select-multiple-option-border;
	border-radius: $plant-works-power-select-multiple-option-border-radius;
	color: $plant-works-power-select-multiple-selection-color;
	background-color: $plant-works-power-select-multiple-selection-background-color;
	padding: $plant-works-power-select-multiple-option-padding;
	display: inline-block;
	line-height: $plant-works-power-select-multiple-option-line-height;
	float: left;
	margin: 2px 0 2px 3px;
}

.plant-works-power-select-multiple-remove-btn {
	cursor: pointer;

	&:not(:hover) {
		opacity: 0.5;
	}
}

// Search bar
.plant-works-power-select-search {
	padding: 4px;
}

.plant-works-power-select-search-input {
	border: $plant-works-power-select-search-field-border;
	border-radius: $plant-works-power-select-search-input-border-radius;
	width: 100%;
	font-size: inherit;
	line-height: inherit;
	padding: 0 5px;

	&:focus {
		border: $plant-works-power-select-search-field-focus-border;
		box-shadow: $plant-works-power-select-focus-box-shadow;

		@if $plant-works-power-select-focus-outline {
			outline: $plant-works-power-select-focus-outline;
		}
	}
}

// Dropdown
.plant-works-power-select-dropdown {
	border-left: $plant-works-power-select-dropdown-left-border;
	border-right: $plant-works-power-select-dropdown-right-border;
	line-height: $plant-works-power-select-line-height;
	border-radius: $plant-works-power-select-dropdown-default-border-radius;
	box-shadow: $plant-works-power-select-dropdown-box-shadow;
	overflow: hidden;
	color: $plant-works-power-select-text-color;
}

.plant-works-power-select-dropdown.ember-basic-dropdown-content--above {
	@if ($plant-works-power-select-dropdown-margin !=0) {
		transform: translateY(calc(-1 * #{$plant-works-power-select-dropdown-margin}));
	}

	border-top: $plant-works-power-select-dropdown-top-border;
	border-bottom: $plant-works-power-select-dropdown-contiguous-border;
	border-bottom-left-radius: $plant-works-power-select-opened-border-radius;
	border-bottom-right-radius: $plant-works-power-select-opened-border-radius;
}

.plant-works-power-select-dropdown.ember-basic-dropdown-content--below,
.plant-works-power-select-dropdown.ember-basic-dropdown-content--in-place {
	@if ($plant-works-power-select-dropdown-margin !=0) {
		transform: translateY(#{$plant-works-power-select-dropdown-margin});
	}

	border-top: $plant-works-power-select-dropdown-contiguous-border;
	border-bottom: $plant-works-power-select-dropdown-bottom-border;
	border-top-left-radius: $plant-works-power-select-opened-border-radius;
	border-top-right-radius: $plant-works-power-select-opened-border-radius;
}

.plant-works-power-select-dropdown.ember-basic-dropdown-content--in-place {
	// Dropdown when rendered in place
	width: 100%;
}

// Options
.plant-works-power-select-options {
	list-style: none;
	margin: 0;
	padding: 0;
	user-select: none;
	-webkit-user-select: none;

	&[role="listbox"] {
		overflow-y: auto;
		/* in firefox in windows this can cause a word-break issue. Try `overflow-y: scroll` if that happens */
		-webkit-overflow-scrolling: touch;

		@if unitless($plant-works-power-select-line-height) {
			max-height: #{$plant-works-power-select-number-of-visible-options * $plant-works-power-select-line-height}em;
		}

		@else {
			max-height: $plant-works-power-select-number-of-visible-options * $plant-works-power-select-line-height;
		}
	}
}

.plant-works-power-select-option {
	cursor: pointer;
	padding: 0 $plant-works-power-select-option-padding;
}

.plant-works-power-select-group[aria-disabled="true"] {
	color: $plant-works-power-select-disabled-option-color;
	cursor: not-allowed;
}

.plant-works-power-select-group[aria-disabled="true"] .plant-works-power-select-option,
.plant-works-power-select-option[aria-disabled="true"] {
	color: $plant-works-power-select-disabled-option-color;
	pointer-events: none;
	cursor: not-allowed;
}

.plant-works-power-select-option[aria-selected="true"] {
	background-color: $plant-works-power-select-selected-background;
}

.plant-works-power-select-option[aria-current="true"] {
	background-color: $plant-works-power-select-highlighted-background;
	color: $plant-works-power-select-highlighted-color;
}

// Groups
.plant-works-power-select-group-name {
	cursor: default;
	font-weight: bold;
}

// Disabled styles
.plant-works-power-select-trigger[aria-disabled=true] {
	background-color: $plant-works-power-select-disabled-background-color;
}

// LTR styles
.plant-works-power-select-trigger {
	padding: $plant-works-power-select-trigger-ltr-padding;
}

.plant-works-power-select-selected-item,
.plant-works-power-select-placeholder {
	margin-left: 8px;
}

.plant-works-power-select-status-icon {
	right: 5px;
}

.plant-works-power-select-clear-btn {
	right: $plant-works-power-select-clear-btn-margin;
}

.plant-works-power-select-group {
	.plant-works-power-select-group {
		.plant-works-power-select-group-name {
			padding-left: 3 * $plant-works-power-select-option-padding;
		}

		.plant-works-power-select-option {
			padding-left: 5 * $plant-works-power-select-option-padding;
		}
	}

	.plant-works-power-select-option {
		padding-left: 3 * $plant-works-power-select-option-padding;
	}

	.plant-works-power-select-group-name {
		padding-left: $plant-works-power-select-option-padding;
	}
}

// RTL styles
.plant-works-power-select-trigger[dir=rtl] {
	padding: $plant-works-power-select-trigger-rtl-padding;

	.plant-works-power-select-selected-item,
	.plant-works-power-select-placeholder {
		margin-right: 8px;
	}

	.plant-works-power-select-multiple-option {
		float: right;
	}

	.plant-works-power-select-trigger-multiple-input {
		float: right;
	}

	.plant-works-power-select-status-icon {
		left: 5px;
		right: initial;
	}

	.plant-works-power-select-clear-btn {
		left: $plant-works-power-select-clear-btn-margin;
		right: initial;
	}
}


.plant-works-power-select-dropdown[dir=rtl] {
	.plant-works-power-select-group {
		.plant-works-power-select-group {
			.plant-works-power-select-group-name {
				padding-right: 3 * $plant-works-power-select-option-padding;
			}

			.plant-works-power-select-option {
				padding-right: 5 * $plant-works-power-select-option-padding;
			}
		}

		.plant-works-power-select-option {
			padding-right: 3 * $plant-works-power-select-option-padding;
		}

		.plant-works-power-select-group-name {
			padding-right: $plant-works-power-select-option-padding;
		}
	}
}
