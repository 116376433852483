/*
 * Overriding jsTree styles
 */

.jstree-default .jstree-node,
.jstree-default .jstree-icon {
	background-image: url("../img/32px.png");
}

.jstree-default .jstree-file {
	background: url("../img/32px.png") -100px -68px no-repeat;
}

.jstree-default .jstree-folder {
	background: url("../img/32px.png") -260px -4px no-repeat;
}

#jstree-dnd.jstree-default .jstree-ok,
#jstree-dnd.jstree-default .jstree-er {
	background-image: url("../img/32px.png");
}

.jstree-default-small .jstree-node,
.jstree-default-small .jstree-icon {
	background-image: url("../img/32px.png");
}

.jstree-default-small .jstree-file {
	background: url("../img/32px.png") -103px -71px no-repeat;
}

.jstree-default-small .jstree-folder {
	background: url("../img/32px.png") -263px -7px no-repeat;
}

#jstree-dnd.jstree-default-small .jstree-ok,
#jstree-dnd.jstree-default-small .jstree-er {
	background-image: url("../img/32px.png");
}

.jstree-default-large .jstree-node,
.jstree-default-large .jstree-icon {
	background-image: url("../img/32px.png");
}

.jstree-default-large .jstree-file {
	background: url("../img/32px.png") -96px -64px no-repeat;
}

.jstree-default-large .jstree-folder {
	background: url("../img/32px.png") -256px 0px no-repeat;
}

#jstree-dnd.jstree-default-large .jstree-ok,
#jstree-dnd.jstree-default-large .jstree-er {
	background-image: url("../img/32px.png");
}

@media (max-width: 768px) {
	#jstree-dnd.jstree-dnd-responsive>.jstree-ok {
		background-image: url("../img/40px.png");
		background-position: 0 -200px;
		background-size: 120px 240px;
	}

	#jstree-dnd.jstree-dnd-responsive>.jstree-er {
		background-image: url("../img/40px.png");
		background-position: -40px -200px;
		background-size: 120px 240px;
	}

	.jstree-default-responsive .jstree-icon {
		background-image: url("../img/40px.png");
	}

	.jstree-default-responsive .jstree-node,
	.jstree-default-responsive .jstree-icon,
	.jstree-default-responsive .jstree-node>.jstree-ocl,
	.jstree-default-responsive .jstree-themeicon,
	.jstree-default-responsive .jstree-checkbox {
		background-image: url("../img/40px.png");
		background-size: 120px 240px;
	}

	.jstree-default-responsive .jstree-file {
		background: url("../img/40px.png") 0 -160px no-repeat;
		background-size: 120px 240px;
	}

	.jstree-default-responsive .jstree-folder {
		background: url("../img/40px.png") -40px -40px no-repeat;
		background-size: 120px 240px;
	}
}

.jstree-default>.jstree-container-ul .jstree-loading>.jstree-ocl {
	background: url("../img/throbber.gif") center center no-repeat;
}

.jstree-default-small>.jstree-container-ul .jstree-loading>.jstree-ocl {
	background: url("../img/throbber.gif") center center no-repeat;
}

.jstree-default-large>.jstree-container-ul .jstree-loading>.jstree-ocl {
	background: url("../img/throbber.gif") center center no-repeat;
}

.jstree-default a {
	white-space: normal !important;
	height: auto;
}

.jstree-anchor {
	height: auto !important;
}

.jstree-default li>ins {
	vertical-align: top;
}

.jstree-leaf {
	height: auto;
}

.jstree-leaf a {
	height: auto !important;
}
