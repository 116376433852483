/*
 * Overriding Golden Layout colors, etc.
 */

.lm_goldenlayout,
.lm_content {
	background: transparent;
	border: 0px;
}

.lm_header {
	border-bottom: 1px solid #ccc;
	overflow: hidden;
	height: 50px !important;
}

.lm_stack {
	overflow: hidden;
}

.lm_header .lm_tabs {
	margin: 0px !important;
}

.lm_header .lm_tab {
	font-family: "Noto Sans", sans-serif;
	font-size: 1rem;

	background-color: transparent;

	margin: 0px !important;
	border: 0px !important;
	padding: 12px 24px !important;

	cursor: pointer;
}

.lm_header .lm_tab .lm_close_tab {
	background-color: transparent;
	margin-left: 0.25rem;
	margin-top: 0.9rem;
}

.lm_header .lm_controls>li {
	margin-top: 0.9rem;
}

.lm_header .lm_tab.lm_active {
	padding: 12px 24px 8px;
	height: unset;

	-webkit-box-shadow: none;
	box-shadow: none;

	color: #1976d2;
	border-bottom: 2px solid #1976d2;

	cursor: default;
}

.lm_header .lm_tab .lm_title {
	padding-top: 0;
	text-transform: uppercase;
}

.lm_splitter {
	opacity: 0.4;
}
