/* TODO is this part of the spec? Demos rely on this. */
.plant-works-list-inline {
	list-style: none;
	margin-left: -5px;
	padding-left: 0;
}

.plant-works-list-inline > li {
	display: inline-block;
	padding-left: 5px;
	padding-right: 5px;
}
