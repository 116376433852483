.br-0 {
	border-style: solid;
	border-width: 0 !important;
}

.br-t-0,
.br-y-0 {
	border-style: solid;
	border-top-width: 0 !important;
}

.br-r-0,
.br-x-0 {
	border-style: solid;
	border-right-width: 0 !important;
}

.br-b-0,
.br-y-0 {
	border-style: solid;
	border-bottom-width: 0 !important;
}

.br-l-0,
.br-x-0 {
	border-style: solid;
	border-left-width: 0 !important;
}

.br-1 {
	border-style: solid;
	border-width: 1px !important;
}

.br-t-1,
.br-y-1 {
	border-style: solid;
	border-top-width: 1px !important;
}

.br-r-1,
.br-x-1 {
	border-style: solid;
	border-right-width: 1px !important;
}

.br-b-1,
.br-y-1 {
	border-style: solid;
	border-bottom-width: 1px !important;
}

.br-l-1,
.br-x-1 {
	border-style: solid;
	border-left-width: 1px !important;
}

.br-2 {
	border-style: solid;
	border-width: 2px !important;
}

.br-t-2,
.br-y-2 {
	border-style: solid;
	border-top-width: 2px !important;
}

.br-r-2,
.br-x-2 {
	border-style: solid;
	border-right-width: 2px !important;
}

.br-b-2,
.br-y-2 {
	border-style: solid;
	border-bottom-width: 2px !important;
}

.br-l-2,
.br-x-2 {
	border-style: solid;
	border-left-width: 2px !important;
}

.br-3 {
	border-style: solid;
	border-width: 3px !important;
}

.br-t-3,
.br-y-3 {
	border-style: solid;
	border-top-width: 3px !important;
}

.br-r-3,
.br-x-3 {
	border-style: solid;
	border-right-width: 3px !important;
}

.br-b-3,
.br-y-3 {
	border-style: solid;
	border-bottom-width: 3px !important;
}

.br-l-3,
.br-x-3 {
	border-style: solid;
	border-left-width: 3px !important;
}

.br-4 {
	border-style: solid;
	border-width: 4px !important;
}

.br-t-4,
.br-y-4 {
	border-style: solid;
	border-top-width: 4px !important;
}

.br-r-4,
.br-x-4 {
	border-style: solid;
	border-right-width: 4px !important;
}

.br-b-4,
.br-y-4 {
	border-style: solid;
	border-bottom-width: 4px !important;
}

.br-l-4,
.br-x-4 {
	border-style: solid;
	border-left-width: 4px !important;
}

.br-5 {
	border-style: solid;
	border-width: 5px !important;
}

.br-t-5,
.br-y-5 {
	border-style: solid;
	border-top-width: 5px !important;
}

.br-r-5,
.br-x-5 {
	border-style: solid;
	border-right-width: 5px !important;
}

.br-b-5,
.br-y-5 {
	border-style: solid;
	border-bottom-width: 5px !important;
}

.br-l-5,
.br-x-5 {
	border-style: solid;
	border-left-width: 5px !important;
}
