md-checkbox {
  .md-ripple {

	color: paper-color($accent, "A700");
	color: RGB(var(--accent-A700));

  }

  &.md-checked .md-ripple {

	color: paper-color($background, "600");
	color: RGB(var(--background-600));

  }

  &.md-checked.md-focused .md-container:before {

	background-color: rgba(paper-color($accent, "500"), 0.26);
	background-color: RGBA(var(--accent-500), 0.26);

  }

  .md-ink-ripple {

	color: paper-color($foreground, "2");
	color: var(--foreground-2);

  }

  &.md-checked .md-ink-ripple {

	color: rgba(paper-color($accent, "500"), 0.87);
	color: RGBA(var(--accent-500), 0.87);

  }

  &:not(.md-checked) .md-icon {

	border-color: paper-color($foreground, "2");
	border-color: var(--foreground-2);

  }

  &.md-checked .md-icon {

	background-color: rgba(paper-color($accent, "500"), 0.87);
	background-color: RGBA(var(--accent-500), 0.87);

  }

  &.md-checked .md-icon:after {

	border-color: rgba(paper-contrast-color($accent, "500"), 0.87);
	border-color: RGBA(var(--accent-500-contrast), 0.87);

  }

  &:not([disabled]) {
    &.md-primary {
     @include checkbox-primary;
    }

    &.md-warn {
      .md-ripple {

	color: paper-color($warn, "600");
	color: RGB(var(--warn-600));

      }

      .md-ink-ripple {

	color: paper-color($foreground, "2");
	color: var(--foreground-2);

      }

      &.md-checked .md-ink-ripple {

	color: rgba(paper-color($warn, "500"), 0.87);
	color: RGBA(var(--warn-500), 0.87);

      }

      &:not(.md-checked) .md-icon {

	border-color: paper-color($foreground, "2");
	border-color: var(--foreground-2);

      }

      &.md-checked .md-icon {

	background-color: rgba(paper-color($warn, "500"), 0.87);
	background-color: RGBA(var(--warn-500), 0.87);

      }

      &.md-checked.md-focused:not([disabled]) .md-container:before {

	background-color: rgba(paper-color($warn, "500"), 0.26);
	background-color: RGBA(var(--warn-500), 0.26);

      }

      &.md-checked .md-icon:after {

	border-color: paper-color($background, "200");
	border-color: RGB(var(--background-200));

      }
    }
  }

  &[disabled] {
    &:not(.md-checked) .md-icon {

	border-color: paper-color($foreground, "3");
	border-color: var(--foreground-3);

    }

    &.md-checked .md-icon {

	background-color: paper-color($foreground, "3");
	background-color: var(--foreground-3);

    }

    &.md-checked .md-icon:after {

	border-color: paper-color($background, "200");
	border-color: RGB(var(--background-200));

    }

    .md-icon:after {

	border-color: paper-color($foreground, "3");
	border-color: var(--foreground-3);

    }

    .md-label {

	color: paper-color($foreground, "3");
	color: var(--foreground-3);

    }
  }

}
