@mixin md-autocomplete-input($input-color) {
  md-input-container.md-input-focused {
    .md-input {
      border-color: $input-color;
    }
    label,
    md-icon {
      color: $input-color;
    }
  }
}
@mixin md-autocomplete-progress($container-color, $bar-color) {
  md-progress-linear {
    .md-container {
      background-color: $container-color;
    }
    .md-bar {
      background-color: $bar-color;
    }
  }
}
md-autocomplete {

	background: paper-color($background, "A100");
	background: RGB(var(--background-A100));

  &[disabled]:not([md-floating-label]) {

	background: paper-color($background, "100");
	background: RGB(var(--background-100));

  }
  button {
    md-icon {
      path {

	fill: paper-color($background, "600");
	fill: RGB(var(--background-600));

      }
    }
    &:after {

	background: rgba(paper-color($background, "600"), 0.3);
	background: RGBA(var(--background-600), 0.3);

    }
  }
  input {

	color: paper-color($foreground, "1");
	color: var(--foreground-1);

  }
  &.md-accent {

	@include md-autocomplete-input(paper-color($accent, "500"));
	@include md-autocomplete-input(RGB(var(--accent-500)));


	@include md-autocomplete-progress(paper-color($accent, "100"), paper-color($accent, "500"));
	@include md-autocomplete-progress(RGB(var(--accent-100)), RGB(var(--accent-500)));

  }
  &.md-warn {

	@include md-autocomplete-input(paper-color($warn, "A700"));
	@include md-autocomplete-input(RGB(var(--warn-A700)));


	@include md-autocomplete-progress(paper-color($warn, "100"), paper-color($warn, "500"));
	@include md-autocomplete-progress(RGB(var(--warn-100)), RGB(var(--warn-500)));

  }
}
.md-autocomplete-suggestions-container,
.md-autocomplete-standard-list-container {

	background: paper-color($background, "A100");
	background: RGB(var(--background-A100));

  .md-autocomplete-suggestion {

	color: paper-color($foreground, "1");
	color: var(--foreground-1);

    &:hover,
    &.selected {

	background: rgba(paper-color($background, "500"), 0.18);
	background: RGBA(var(--background-500), 0.18);

    }
  }
}
