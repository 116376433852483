.overflow-none {
	overflow: hidden !important;
}

.overflow-scroll {
	overflow: scroll !important;
}

.overflow-auto {
	overflow: auto !important;
}

.overflow-x-none {
	overflow: hidden !important;
}

.overflow-x-scroll {
	overflow-x: scroll !important;
}

.overflow-x-auto {
	overflow-x: auto !important;
}

.overflow-y-none {
	overflow: hidden !important;
}

.overflow-y-scroll {
	overflow-y: scroll !important;
}

.overflow-y-auto {
	overflow-y: auto !important;
}
