$plant-works-power-select-material-active-color: if(function-exists(paper-color), paper-color($primary), color($primary)) !default;

$plant-works-power-select-default-border-radius: none !default;
$plant-works-power-select-border-color: if(function-exists(paper-color), paper-color($color-grey), color($color-grey)) !default !default;
$plant-works-power-select-trigger-border: none !default;
$plant-works-power-select-trigger-border-bottom: 1px solid if(function-exists(paper-color), paper-color($color-grey), color($color-grey)) !default !default;
$plant-works-power-select-active-trigger-border: none !default;
$plant-works-power-select-active-trigger-border-bottom: 2px solid $plant-works-power-select-material-active-color !default;

$plant-works-power-select-dropdown-border: none !default;
$plant-works-power-select-highlighted-color: inherit !default;
$plant-works-power-select-highlighted-background: #eee !default;
$plant-works-power-select-selected-background: #e1e1e1 !default;
$plant-works-power-select-line-height: 2.5 !default;
$plant-works-power-select-focus-outline: none !default;
$plant-works-power-select-dropdown-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12) !default;
$plant-works-power-select-search-field-border: none !default;
$plant-works-power-select-search-field-focus-border: none !default;
$plant-works-power-select-multiple-option-border: none !default;
$plant-works-power-select-multiple-option-border-radius: 15px !default;
$plant-works-power-select-multiple-option-padding: 0 10px !default;
$plant-works-power-select-multiple-option-line-height: 2 !default;

@keyframes drop-fade-below {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		transform: translateY(3px);
	}
}

@keyframes drop-fade-above {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}

	100% {
		opacity: 1;
		transform: translateY(-3px);
	}
}

.ember-basic-dropdown-content--below.ember-basic-dropdown--transitioning-in {
	animation: drop-fade-below .15s;
}

.ember-basic-dropdown-content--below.ember-basic-dropdown--transitioning-out {
	animation: drop-fade-below .15s reverse;
}

// When opening above the trigger
.ember-basic-dropdown-content--above.ember-basic-dropdown--transitioning-in {
	animation: drop-fade-above .15s;
}

.ember-basic-dropdown-content--above.ember-basic-dropdown--transitioning-out {
	animation: drop-fade-above .15s reverse;
}

.plant-works-power-select-placeholder {
	transition: transform 0.2s, color 0.2s;
	transform-origin: 0 0;

	.plant-works-power-select-trigger--active & {
		transform: scale(0.7) translateY(-10px);
		color: $plant-works-power-select-material-active-color;
	}
}
