.md-searchbox {
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
	padding-left: 10.667px;
	height: 48px;
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
	width: auto;
}

.md-searchinput {
	border: none;
	outline: none;
	height: 100%;
	width: 100%;
	padding: 0;
}

