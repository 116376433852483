md-select.md-table-select {
	margin: 0;

	>.md-select-value {
		padding: 0;
		min-width: 0;
		min-height: 24px;
		border-bottom: 0 !important;

		>span {
			display: block;
			height: auto;
			transform: none !important;

			>.md-text {
				display: inherit;
				height: inherit;
				transform: inherit;
			}

			&.md-select-icon {
				display: flex;
				align-items: center;
				height: 24px;
				margin: 0;

				&:after {
					top: initial;
				}
			}
		}
	}
}

.md-select-menu-container.md-table-select,
.md-select-menu-container.md-pagination-select {
	margin-left: -2px;
	border-radius: 2px;

	md-select-menu,
	md-content {
		border-radius: inherit;
	}

	md-content {
		padding: 0;
	}
}

.md-select-menu-container.md-table-select .md-text {
	font-size: 13px;
}

.md-select-menu-container.md-pagination-select .md-text {
	font-size: 12px;
}
