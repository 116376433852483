$plant-works-swiper-navigation-buttons-distance: 48px !default;
$plant-works-swiper-bottom: 24px !default;
$plant-works-swiper-callout-size: 69px !default;
$plant-works-swiper-placeholder-size: $plant-works-swiper-bottom+$plant-works-swiper-callout-size;
$plant-works-swiper-text-padding-sides: 16px !default;
$plant-works-swiper-pagination-bullet-color: #000 !default;
$plant-works-swiper-pagination-bullet-color-active: #007aff !default;
$plant-works-swiper-slide-background: #fff !default;
$plant-works-swiper-container-background: #ccc !default;
$plant-works-swiper-dialog-height: 80% !default;
$plant-works-swiper-subhead-height: 72px !default;

md-dialog.plant-works-swiper-dialog {
	max-width: 50%;
	height: $plant-works-swiper-dialog-height;
}

.plant-works-swiper-content-container {
	position: relative;

	.plant-works-swiper-container {
		width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		transition-property: transform;
		overflow: hidden;
		background-color: $plant-works-swiper-container-background;

		.plant-works-swiper-wrapper {
			width: 100%;
			height: 100%;
			display: flex;
			box-sizing: content-box;
		}
	}



	.plant-works-swiper-fixed-content {
		position: absolute;
		bottom: $plant-works-swiper-bottom;
		width: 100%;
		height: $plant-works-swiper-callout-size;
		box-sizing: border-box;
	}

	.plant-works-swiper-callout {
		display: flex;
		justify-content: center;
		z-index: 81;

		.md-button {
			margin: 0;
		}
	}

	.plant-works-swiper-pagination {
		text-align: center;
		line-height: 8px;
		margin-top: 24px;

		.plant-works-swiper-pagination-bullet {
			margin: 0 5px;
			cursor: pointer;
			width: 8px;
			height: 8px;
			border-radius: 100%;
			background: $plant-works-swiper-pagination-bullet-color;
			opacity: .2;
			display: inline-block;

			&.active {
				opacity: 1;
				background: $plant-works-swiper-pagination-bullet-color-active;
			}
		}
	}
}

/* DIALOG STYLES */
.plant-works-swiper-dialog-container {
	.plant-works-swiper-previous-button {
		margin-right: $plant-works-swiper-navigation-buttons-distance;
	}

	.plant-works-swiper-next-button {
		margin-left: $plant-works-swiper-navigation-buttons-distance;
	}
}

/* SLIDE STYLES */

.plant-works-swiper-slide {
	@extend .layout-column;
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative;
	background-color: $plant-works-swiper-slide-background;

	.plant-works-swiper-slide-media {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50% 50%;

		flex: 1;
	}

	.plant-works-swiper-text {
		@extend .layout-column;
		@extend .layout-align-center-center;
		text-align: center;

		.plant-works-swiper-headline {
			margin-top: 15px;
			padding: 0 $plant-works-swiper-text-padding-sides;
			font-weight: 500;
			font-size: 24px;
			line-height: 24px;
		}

		.plant-works-swiper-subhead {
			margin-top: 18px;
			font-size: 16px;
			line-height: 18px;
			flex: 0 0 $plant-works-swiper-subhead-height;
			padding: 0 $plant-works-swiper-text-padding-sides;
		}

		.plant-works-swiper-fixed-content-placeholder {
			flex: 0 0 $plant-works-swiper-placeholder-size;
		}
	}
}

/*
 * Read: when the aspect ratio is square or landscape AND
 * width is at least 959px (the same breakpoint as fullscren dialogs use)
 */
@media screen and (min-aspect-ratio: 1/1) and (max-width: 959px) {
	.plant-works-swiper-slide {
		flex-direction: row;

		.plant-works-swiper-slide-media {
			flex: 1;
		}

		.plant-works-swiper-text {
			flex: 1 1;
			align-items: flex-start;
			text-align: left;

			.plant-works-swiper-fixed-content-placeholder {
				flex: 0 0 $plant-works-swiper-placeholder-size;
			}
		}
	}

	.plant-works-swiper-dialog-container {
		.plant-works-swiper-fixed-content {
			width: auto;
			bottom: initial;
			top: 50%;
			left: 50%;

			.plant-works-swiper-callout {
				justify-content: flex-start;
				padding: 0 $plant-works-swiper-text-padding-sides;
			}

			.plant-works-swiper-pagination {
				padding: 0 $plant-works-swiper-text-padding-sides;
			}
		}
	}
}
