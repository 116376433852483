/*
 * Modifications & Bug-fixes :-(
 */
body {
	min-height: 100% !important;
	min-width: 100% !important;
	max-width: 100% !important;
}

b,
strong {
	font-weight: 600;
}

/*
 * Generic utility classes for specific elements.
 */
footer.page-footer {
	bottom: 0;
	color: #fff;
}

footer.page-footer .footer-copyright {
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.2);
	color: rgba(255, 255, 255, 0.6);
}

footer.page-footer a {
	color: #fff;
}

@supports (position: sticky) {
	header.sticky-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}

div.md-dialog-container {
	top: 75px;
	max-height: calc(100% - 100px);
}

/*
* Class to blink an element
*/

.blink {
	animation: blinker 3s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}
