.bg-plantworks {
    background-color: #80d8ff;
    background: linear-gradient(100deg, #eb2f3d, #bb3c59, #754f80, #3560a5, #2267af);
}

.bg-plantworks-component {
	background-color: #80d8ff;
	background: linear-gradient(100deg,#2096ff,#05ffa3)
}

.bg-plantworks-component-reverse {
	background-color: #80d8ff;
	background: linear-gradient(100deg,#05ffa3,#2096ff)
}
