/* Material Design Icons Font */
@import "ember-mdi";

/* PlantWorks Design Specification Language */
@import 'pworks-dsl';

/* General additions */
@import 'bg-custom';

/* HTML Element Customizations */
@import 'elements';

/* jQuery Plugin Customizations */
@import 'jstree';
@import 'golden-layout';

/*
 * Overriding pworks-dsl styles
 */
body {
	line-height: 1.4;
}

input {
	text-align: unset;
}

md-toolbar md-select {
	background-color: transparent !important;
	border-bottom: none !important;
	margin-top:1em !important;
}

md-toolbar md-select .md-select-value {
	border-bottom-color: transparent !important;
}

header.sticky-top {
	z-index: 10;
}

md-autocomplete-wrap > md-input-container > label {
	margin-bottom: 0px;
}

table.md-table.md-row-select td.md-cell:nth-child(2), table.md-table.md-row-select th.md-column:nth-child(2) {
	padding: 0 0 0 1rem;
}

table.md-table td.md-cell:last-child, table.md-table th.md-column:last-child {
	padding: 0 1rem 0 0;
}

table.md-table.md-row-select td.md-cell:nth-child(n+3):nth-last-child(n+2), table.md-table.md-row-select th.md-column:nth-child(n+3):nth-last-child(n+2) {
	padding: 0;
}

.md-subheader .md-subheader-inner {
	padding: 0.5rem 1rem;
}

/*
 * Overriding liquid-child stuff..
 */
div#plantworks-webapp-server-template-bhairavi-main-row-outlet > div.liquid-container > div.liquid-child {
	flex: 1;
	flex-direction: row;

	display: flex;
	box-sizing: border-box;

	align-items: center;
	align-content: center;

	justify-content: center;
}

/*
 * Setting CodeMirror defaults
 */
.CodeMirror {
	height: auto;
}
