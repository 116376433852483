.plant-works-password-strength {
	clear: both;

	.plant-works-password-strength-label {
		white-space: nowrap;
		font-size: 13px;
	}

	.plant-works-input-error {
		margin-top: 0;
		opacity: 1;
	}
}
