$card-border-radius: 2px !default;

md-card {

	color: paper-color($foreground, "1");
	color: var(--foreground-1);


	background-color: paper-color($background, "A100");
	background-color: RGB(var(--background-A100));

  border-radius: $card-border-radius;

  .md-card-image {
    border-radius: $card-border-radius $card-border-radius 0 0;
  }

  md-card-header {
    md-card-avatar {
      md-icon {

	color: paper-color($background, "50");
	color: RGB(var(--background-50));


	background-color: paper-color($foreground, "3");
	background-color: var(--foreground-3);

      }
    }

    md-card-header-text {
      .md-subhead {

	color: paper-color($foreground, "2");
	color: var(--foreground-2);

      }
    }
  }

  md-card-title {
    md-card-title-text {
      &:not(:only-child) {
        .md-subhead {

	color: paper-color($foreground, "2");
	color: var(--foreground-2);

        }
      }
    }
  }
}
