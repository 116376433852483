.md-button {

  &:not([disabled]) {
    &:hover {

	background-color: rgba(paper-color($background, "500"), 0.2);
	background-color: RGBA(var(--background-500), 0.2);

    }
    &.md-focused {

	background-color: rgba(paper-color($background, "500"), 0.2);
	background-color: RGBA(var(--background-500), 0.2);

    }
    &.md-icon-button:hover {
      background-color: transparent;
    }
  }

  &.md-fab {

	background-color: paper-color($accent, "500");
	background-color: RGB(var(--accent-500));


	color: paper-contrast-color($accent, "500");
	color: RGB(var(--accent-500-contrast));

    md-icon {

	color: paper-contrast-color($accent, "500");
	color: RGB(var(--accent-500-contrast));

    }
    &:not([disabled]) {
      &:hover {

	background-color: paper-color($accent, "A700");
	background-color: RGB(var(--accent-A700));

      }
      &.md-focused {

	background-color: paper-color($accent, "A700");
	background-color: RGB(var(--accent-A700));

      }
    }
  }

  &.md-primary {

	color: paper-color($primary, "500");
	color: RGB(var(--primary-500));

    &.md-raised,
    &.md-fab {

	color: paper-contrast-color($primary, "500");
	color: RGB(var(--primary-500-contrast));


	background-color: paper-color($primary, "500");
	background-color: RGB(var(--primary-500));

      &:not([disabled]) {
        md-icon {

	color: paper-contrast-color($primary, "500");
	color: RGB(var(--primary-500-contrast));

        }
        &:hover {

	background-color: paper-color($primary, "600");
	background-color: RGB(var(--primary-600));

        }
        &.md-focused {

	background-color: paper-color($primary, "600");
	background-color: RGB(var(--primary-600));

        }
      }
    }
    &:not([disabled]) {
      md-icon {

	color: paper-color($primary, "500");
	color: RGB(var(--primary-500));

      }
    }
  }
  &.md-fab {

	background-color: paper-color($accent, "500");
	background-color: RGB(var(--accent-500));


	color: paper-contrast-color($accent, "500");
	color: RGB(var(--accent-500-contrast));

    &:not([disabled]) {
      .md-icon {

	color: paper-contrast-color($accent, "500");
	color: RGB(var(--accent-500-contrast));

      }
      &:hover {

	background-color: paper-color($accent, "A700");
	background-color: RGB(var(--accent-A700));

      }
      &.md-focused {

	background-color: paper-color($accent, "A700");
	background-color: RGB(var(--accent-A700));

      }
    }
  }

  &.md-raised {

	color: paper-color($background, "900");
	color: RGB(var(--background-900));


	background-color: paper-color($background, "50");
	background-color: RGB(var(--background-50));

    &:not([disabled]) {
      md-icon {

	color: paper-color($background, "900");
	color: RGB(var(--background-900));

      }
      &:hover {

	background-color: paper-color($background, "50");
	background-color: RGB(var(--background-50));

      }
      &.md-focused {

	background-color: paper-color($background, "200");
	background-color: RGB(var(--background-200));

      }
    }
  }

  &.md-warn {

	color: paper-color($warn, "500");
	color: RGB(var(--warn-500));


    &.md-raised,
    &.md-fab {

	color: paper-contrast-color($warn, "500");
	color: RGB(var(--warn-500-contrast));


	background-color: paper-color($warn, "500");
	background-color: RGB(var(--warn-500));

      &:not([disabled]) {
        md-icon {

	color: paper-contrast-color($warn, "500");
	color: RGB(var(--warn-500-contrast));

        }
        &:hover {

	background-color: paper-color($warn, "600");
	background-color: RGB(var(--warn-600));

        }
        &.md-focused {

	background-color: paper-color($warn, "600");
	background-color: RGB(var(--warn-600));

        }
      }
    }
    &:not([disabled]) {
      md-icon {

	color: paper-color($warn, "500");
	color: RGB(var(--warn-500));

      }
    }
  }

  &.md-accent {

	color: paper-color($accent, "500");
	color: RGB(var(--accent-500));

    &.md-raised,
    &.md-fab {

	color: paper-contrast-color($accent, "500");
	color: RGB(var(--accent-500-contrast));


	background-color: paper-color($accent, "500");
	background-color: RGB(var(--accent-500));

      &:not([disabled]) {
        md-icon {

	color: paper-contrast-color($accent, "500");
	color: RGB(var(--accent-500-contrast));

        }
        &:hover {

	background-color: paper-color($accent, "A700");
	background-color: RGB(var(--accent-A700));

        }
        &.md-focused {

	background-color: paper-color($accent, "A700");
	background-color: RGB(var(--accent-A700));

        }
      }
    }
    &:not([disabled]) {
      md-icon {

	color: paper-color($accent, "500");
	color: RGB(var(--accent-500));

      }
    }
  }

  &[disabled],
  &.md-raised[disabled],
  &.md-fab[disabled],
  &.md-accent[disabled],
  &.md-warn[disabled] {

	color: paper-color($foreground, "3");
	color: var(--foreground-3);

    cursor: default;

    md-icon {

	color: paper-color($foreground, "3");
	color: var(--foreground-3);

    }
  }
  &.md-raised[disabled],
  &.md-fab[disabled] {

	background-color: paper-color($foreground, "4");
	background-color: var(--foreground-4);

  }
  &[disabled] {
    background-color: transparent;
  }
}


._md {
  a:not(.md-button) {
    &.md-primary {

	color: paper-color($primary, "500");
	color: RGB(var(--primary-500));


      &:hover {

	color: paper-color($primary, "700");
	color: RGB(var(--primary-700));

      }
    }

    &.md-accent {

	color: paper-color($accent, "500");
	color: RGB(var(--accent-500));


      &:hover {

	color: paper-color($accent, "A700");
	color: RGB(var(--accent-A700));

      }
    }

    &.md-warn {

	color: paper-color($warn, "500");
	color: RGB(var(--warn-500));


      &:hover {

	color: paper-color($warn, "700");
	color: RGB(var(--warn-700));

      }
    }
  }
}
