md-input-container .plant-works-power-select-trigger {
	border-bottom-width: 0px;
	line-height: 1.4;
	min-height: 0px;
	padding-right: 0px;
}

md-input-container .plant-works-power-select-selected-item {
	line-height: 1.4;
}

md-autocomplete.plant-works-power-select-trigger {
	border-bottom-width: 0px;
	line-height: 1.4;
	min-height: 0px;
	padding-right: 0px;
}

